<template>
  <router-view/>   
  <ConfirmDialog/>
  <Dialog :modal="true" v-model:visible="app_loading" :showHeader="false" :closable="false" :keepInViewport="true">
    <div  class="text-center p-2">
      <ProgressSpinner/>
      <br/>
      <span>{{this.$store.state.app.loading}}</span>
    </div>
  </Dialog>
  <Toast position="bottom-center" :autoZIndex="true"/>
</template>
<script>
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: {
    Toast,ConfirmDialog,Dialog,ProgressSpinner
  },
  computed: {
    app_loading() {
      return this.$store.state.app.loading!==null
    }
  }
}
</script>
<style lang="scss">
#app {
  min-height: 100vh;
}
</style>

