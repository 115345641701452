<template>
  <div>
    <div class="display-mode-toggle" @click="toggleMenu">
      <i class="pi pi-user"></i> {{$store.getters['user/fullname']}}
    </div>
    <Menu ref="menu" :model="items" :popup="true"/>
  </div>
</template>

<script>
import Menu from 'primevue/menu';
import { UserService } from '@/services/user';

export default {
  components: {
    Menu
  },
  props: {
  },
  data() {
    return {
      items: [
				{
					label: 'Logout',
					icon: 'pi pi-sign-out',
					command: () => {
            UserService.logout()
            this.$store.dispatch('user/logout')
            setTimeout(()=>{window.location.href = '/'},100)
					}
				}
			]
    }
  },
  mounted() {
  },
  methods: {
    toggleMenu(){
      this.$refs.menu.toggle(event);
    }
  }
}
</script>

<style lang="scss">
.display-mode-toggle {
  display: block;
  padding: 0 10px;
  text-align: center;
}
</style>