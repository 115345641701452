<template>
  <div class="edit-order-items">
    <div v-for="order in orders" :key="'order-'+order.orderable_id">
      <Checkbox :disabled="order.status==='delivered'" :value="order.orderable_id" :id="'order-check-'+order.orderable_id" v-model="new_orders" />
      <label :for="'order-check-'+order.orderable_id">
        &nbsp;
        <i class="pi pi-check" v-if="order.status==='delivered'"></i>
        <i class="pi pi-clock" v-else-if="order.status==='ordered'"></i>
        <i class="pi pi-times" v-else-if="order.status==='cancelled'"></i>
        <i class="pi pi-minus" v-else></i>
        &nbsp;<strong>{{order.title}}</strong>
      </label>
    </div>
  </div>
  
  <p class="text-right">
    <Button type="button" icon="pi pi-check" :label="$t('save_order')" iconPos="left" @click="doDoctorOrder" />
  </p>
</template>

<script>
import { PatientService } from '@/services/patient';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    Button,Checkbox
  },
  data () {
    return {
      new_orders: []
    }
  },
  props: ['orders','pid'],
  emits: ['change'],
  mounted() {
    this.orders.map((item)=>{
      if(item.status === 'ordered' || item.status === 'delivered') {
        this.new_orders.push( item.orderable_id )
      }
    })
  },
  methods: {
    doDoctorOrder() {
      this.$store.dispatch('app/showLoading')
      PatientService.order(this.pid,{orderable_ids:this.new_orders})
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss">
.edit-order-items {
  margin-bottom: 10px;
  &>div {
    padding: 10px 0;
    line-height: 1.4em;
    border-bottom: 1px dashed var(--surface-e);
    
  }
}
</style>