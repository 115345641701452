{
  "hello": "สวัสดี",
  "hn": "H.N.",
  "gender": "เพศ",
  "age": "อายุ",
  "admitted": "แอดมิท",
  "ward": "วอร์ด",
  "bed": "เตียง",
  "spo2": "SpO2",
  "bp": "BP",
  "pulse": "ชีพจร",
  "clear_image": "ล้างค่ารูป",
  "take_photo": "ถ่ายรูป",
  "drag_and_drop": "ลากและวางไฟล์",
  "or_click_to_choose_file": "หรือคลิกเพื่อเลือกรูป",
  "open_camera": "เปิดกล้อง",
  "green": "สีเขียว",
  "yellow": "สีเหลือง",
  "red": "สีแดง",
  "register": "ลงทะเบียน",
  "dashboard": "ภาพรวม",
  "record": "บันทึก",
  "medicine": "ยา",
  "new_patient": "เพิ่มคนไข้",
  "total_patient": "คนไข้ทั้งหมด",
  "daily_admission": "รับเข้าวันนี้",
  "daily_discharge": "จำนวนออกวันนี้",
  "daily_transfer": "จำนวนส่งต่อวันนี้",
  "transfer_pending": "รอการส่งต่อ",
  "name": "ชื่อ",
  "note": "บันทึก",
  "go_back": "กลับไปก่อนหน้า",
  "records": "รายการบันทึก",
  "date": "วันที่",
  "time": "เวลา",
  "symptoms": "อาการ",
  "chart": "Chart",
  "oxsat": "ค่าออกซิเจน",
  "upload_image_to_auto_read": "อัพโหลดรูปภาพเพื่ออ่านโดยอัตโนมัติ",
  "temperature": "อุณหภูมิ",
  "rr": "RR",
  "blood_pressure": "ความดันโลหิต",
  "sbp": "SBP",
  "dbp": "DBP",
  "symptom": "อาการ",
  "cancel": "ยกเลิก",
  "save": "บันทึก",
  "register_patient": "ลงทะเบียนคนไข้",
  "initial": "คำนำหน้า",
  "firstname": "ชื่อ",
  "lastname": "นามสกุล",
  "dob": "วันเกิด",
  "please_select_ward": "กรุณาเลือกวอร์ด",
  "covid_result": "ผลตรวจโควิท",
  "covid_test_type": "การตรวจโควิท",
  "ud": "UD",
  "cxr": "CXR",
  "add_patient": "เพิ่มคนไข้",
  "save_successfully": "บันทึกสำเร็จแล้ว",
  "line_passcode": "รหัสผ่านเพื่อเข้าไลน์",
  "back_to_dashboard": "กลับไปยังหน้าแรก",
  "male": "ชาย",
  "female": "หญิง",
  "unknown": "ไม่ทราบ",
  "positive": "Positive",
  "progress_note": "Progress Note",
  "image": "รูปภาพ",
  "triage": "คัดกรอง",
  "temp": "อุณหภูมิ",
  "date_time": "วันเวลา",
  "spo2_test": "ทดสอบ SpO2",
  "spo2_before_exercise": "ค่า SpO2 ก่อนออกกำลังกาย",
  "spo2_after_exercise": "ค่า SpO2 หลังออกกำลังกาย",
  "save_draft": "บันทึกร่าง",
  "save_note": "บันทึกและยืนยัน",
  "confirm_change_triage": "ยืนยันการเปลี่ยนแปลงกลุ่มเสี่ยง",
  "notify_doctor": "แจ้งเตือนไปยังแพทย์",
  "notify_doctor_reason": "ระบุเหตุผลที่ต้องการแจ้งเตือนไปยังแพทย์",
  "send_notification_to_doctors": "ส่งการแจ้งเตือนไปยังแพทย์",
  "discharge": "Discharge",
  "discharge_reason": "ระบุเหตุผล",
  "discharge_date": "วันที่ออก",
  "discharge_patient_note": "หมายเหตุ",
  "do_discharge": "Discharge",
  "dead": "เสียชีวิต",
  "move_to_ci": "ย้ายไปยัง Community Isolation",
  "move_status": "สถานะการย้าย",
  "move_date": "วันที่ย้าย",
  "transfer_to_hospital": "ส่งต่อไปยังโรงพยาบาล",
  "transfer_status": "สถานะการส่งต่อ",
  "transfer_date": "วันที่ส่งต่อ",
  "edit_patient": "แก้ไขข้อมูลคนไข้",
  "move_patient": "ย้ายคนไข้",
  "request_move_to_ci": "ขอย้ายไปยัง Community Isolation",
  "request_transfer_to_hospital": "ขอส่งต่อไปยังโรงพยาบาล",
  "citizen_id": "เลขบัตรประชาชน",
  "phone_number": "โทรศัพท์",
  "address": "ที่อยู่",
  "family_information": "ข้อมูลคนในครอบครัว",
  "family_number": "สมาชิกในบ้าน",
  "covid_positive": "ติดโควิท",
  "health_coverage_information": "ข้อมูลสิทธิรักษาพยาบาล",
  "health_coverage": "สิทธิการรักษา",
  "insurance_paper": "รูปเอกสารประกัน",
  "upload_profile_image": "รูปถ่าย",
  "drag_or_click_to_upload_profile_picture": "ลากวางหรือคลิกเพื่ออัพโหลดรูปถ่าย",
  "id_card_image": "รูปบัตรประชาชน",
  "upload_id_card": "อัพโหลดรูปบัตรประชาชน",
  "ci_information": "ข้อมูล Community Isolation",
  "covid_test": "ผลตรวจโควิท",
  "covid_test_date": "วันที่ตรวจ",
  "covid_test_image": "รูปผลตรวจ",
  "test_date": "วันที่ตรวจ",
  "health_information": "ข้อมูลสุขภาพ",
  "hx_allergy": "ประวัติแพ้ยา/อาหาร",
  "hx_medication": "ยาประจำ",
  "universal_coverage": "สิทธิ 30 บาทฯ",
  "social_security": "ประกันสังคม",
  "civil_servant": "ข้าราชการ",
  "private": "ประกันเอกชน",
  "add_tag": "เพิ่มแทคใหม่",
  "edit_tags": "แก้ไขแทค",
  "type_to_add_new_tag": "พิมพ์เพื่อเพิ่มแทคใหม่",
  "save_tags": "บันทึกแทค",
  "doctor_note": "บันทึกแพทย์",
  "upload_oxsat_image_to_auto_read": "อัพโหลดรูปเครื่องวัดออกซิเจนเพื่ออ่านโดยอัตโนมัติ",
  "waiting_list": "คนไข้รอรับ",
  "patient_dashboard": "รายการคนไข้",
  "fulfillments": "การจัดส่ง",
  "picture": "รูปภาพ",
  "color": "สี",
  "round": "ราวด์",
  "all": "ทั้งหมด",
  "id": "รหัส",
  "item": "Item",
  "add_progress_note": "เพิ่ม Progress Note",
  "click_to_round": "คลิกเพื่อราวด์",
  "approve_patient": "อนุมัติ",
  "patient_register": "ลงทะเบียนคนไข้",
  "total_waiting": "จำนวนรอรับ",
  "daily_register": "จำนวนลงทะเบียน",
  "status": "สถานะ",
  "symptom_start_date": "วันที่เริ่มมีอาการ",
  "contacted_orgs": "หน่วยงานที่ติดต่อแล้ว",
  "received_at": "ได้รับ",
  "ordered_at": "สั่ง",
  "no_order": "ไม่มีคำสั่ง",
  "edit_order": "แก้ไขคำสั่ง",
  "doctor_order": "สั่งอุปกรณ์",
  "save_order": "บันทึกคำสั่ง",
  "approve": "อนุมัติ",
  "save_and_sign": "บันทึกและเซ็นทับ",
  "last_round": "ราวด์ล่าสุด",
  "last_progress_note": "Pregress ล่าสุด",
  "admit": "แอดมิท",
  "edit": "แก้ไข",
  "draft": "ฉบับร่าง",
  "saved": "บันทึกแล้ว",
  "confirmed": "ตรวจสอบแล้ว",
  "cancelled": "ยกเลิก",
  "discharge_date_time": "วันเวลา",
  "transfer_patient": "ย้ายคนไข้",
  "move_to": "ย้ายไปยัง",
  "refer_to_hispital": "ส่งต่อโรงพยาบาล",
  "ward_bed": "ย้ายวอร์ดหรือเตียง",
  "transfer_hospital": "โรงพยาบาลที่ส่งต่อ",
  "choose_or_type_tags": "เลือกหรือพิมพ์แทค",
  "ordered": "สั่ง",
  "sent": "ส่งแล้ว",
  "item_sent": "สิ่งที่ส่งแล้ว",
  "transferred": "ย้ายหรือส่งต่อแล้ว",
  "request_for_transfer": "ทำคำขอย้ายหรือส่งต่อ",
  "transfer_request": "คำขอย้ายหรือส่งต่อ",
  "phone": "เบอร์โทร",
  "process": "ทำรายการ",
  "transfer": "ส่งต่อ",
  "view_detail": "รายละเอียด",
  "patient_action": "ทำรายการคนไข้",
  "view_patient": "รายละเอียดคนไข้",
  "close": "ปิด",
  "transfer_or_move": "ส่งต่อหรือย้าย",
  "unit_information": "ข้อมูลการแอดมิท",
  "type": "ประเภท",
  "ward_zone": "วอร์ด/โซน",
  "community_isolation": "Community Isolation",
  "home_isolation": "Home Isolation",
  "staff_note": "บันทึกสตาฟฟ์",
  "patient_note": "บันทึกคนไข้",
  "day": "วัน",
  "view": "แสดง",
  "covid_day": "ติดโควิท",
  "unit": "หน่วย",
  "last_progress": "ความคืบหน้าล่าสุด",
  "print": "พิมพ์",
  "print_medical_file": "พิมพ์เวชระเบียน",
  "creating_pdf_file": "กำลังสร้างไฟล์ PDF",
  "progress": "Progress",
  "other_symptom": "อาการอื่นๆ",
  "other_symptoms": "อาการอื่นๆ",
  "other_uds": "โรคอื่นๆ",
  "please_check_or_click_to_change_triage": "กรุณาตรวจสอบกลุ่มเสี่ยง หรือคลิกเพื่อเปลี่ยนค่า",
  "triage_change_detected_to": "แนะนำเปลี่ยนแปลงกลุ่มเสี่ยงเป็น",
  "do_you_want_to_change_triage": "ต้องการเปลี่ยนกลุ่มเสี่ยงหรือไม่?",
  "preparing": "กำลังเตรียม",
  "consent_form": "ฟอร์มยินยอม",
  "order_medicine_and_equiptment": "สั่งยาและอุปกรณ์",
  "new_waiting_patient": "เพิ่มคนไข้รอรับ",
  "new_patient_added": "เพิ่มคนไข้เรียบร้อยแล้ว",
  "weight": "น้ำหนัก",
  "height": "ส่วนสูง",
  "patient_list": "รายการคนไข้",
  "no_record_found": "ไม่พบข้อมูล",
  "transfer_requests": "คำขอย้าย",
  "no_ward": "ไม่มีวอร์ด",
  "discharge_list": "Discharge List",
  "admission_count": "จำนวนรับเข้า",
  "today": "วันนี้",
  "patient_triage_count": "แบ่งตามคัดกรอง",
  "progress_note_count": "จำนวน Progress Note",
  "patient_ward_count": "จำนวนคนไข้ แบ่งตามวอร์ด",
  "total_cases": "จำนวนเคสทั้งหมด",
  "cumulative_case_count": "จำนวนเคสสะสม",
  "patient_age_range": "แบ่งตามช่วงอายุ",
  "patient_gender_count": "แบ่งตามเพศ",
  "discharge_statistic": "สถิติการ Discharge",
  "discharge_disposition": "ประเภทการ Discharge",
  "discharge_count": "จำนวน Discharge",
  "patient_count": "จำนวนคนไข้",
  "current_admit": "คนไข้ที่แอดมิทอยู่",
  "waiting_patient": "คนไข้รอรับ",
  "sign_medical_certificate": "เซ็นใบรับรองแพทย์",
  "confirm_sign_medical_certificate": "ยืนยันเซ็นใบรับรองแพทย์",
  "sign_medical_Certification": "เซ็นใบรับรองแพทย์",
  "print_medical_Certification": "พิมพ์ใบรับรองแพทย์",
  "signing_medical_certification": "กำลังเซ็นใบรับรองแพทย์",
  "sign_successfully": "เซ็นสำเร็จแล้ว",
  "print_admission_note": "พิมพ์ใบแรกรับ",
  "creating_admission_note": "กำลังสร้างใบแรกรับ",
  "hospital_ward": "Hospital Ward",
  "discharged": "Discharged"
}