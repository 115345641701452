<template>
  <div class="content-container">
    <h1>{{$t('patient_register')}}</h1>
    <PatientForm :id="patient_id" action="register" @done="formSubmitted" @back="$router.push('/main/dashboard')"/>
  </div>
</template>

<script>
import { PatientService } from '@/services/patient';
import PatientForm  from '@/components/PatientForm';
export default {
  components: {
    PatientForm
  },
  data() {
    return {
      patient_id: null,
      submitted: false
    }
  },
  mounted() {
  },
  methods: {
    formSubmitted() {
      this.submitted = true
    }
  },
}
</script>

<style lang="scss" scoped>

</style>