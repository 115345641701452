import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import i18n from './i18n'
import ToastService from 'primevue/toastservice';
import ConfirmService from "primevue/confirmationservice";

createApp(App)
  .use(store).use(PrimeVue, {ripple: true})
  .use(ToastService).use(ConfirmService)
  .directive('ripple', Ripple).use(router).use(i18n).mount('#app')
