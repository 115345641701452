<template>
  <div class="content-container">
    <div class="p-fluid grid">
      <div class="col md:col-10  lg:col-9 col-12">
        <div class="formgroup grid-col-2">
          <div class="form-item">
            <label>{{$t('name')}}</label>
            <div class="form-item-box">{{patient.prefix}} {{patient.firstname}} {{patient.lastname}}</div>
          </div>
          <div class="form-item">
            <label>{{$t('citizen_id')}}</label>
            <div class="form-item-box">{{patient.cid}}</div>
          </div>
          <div class="form-item">
            <label>{{$t('gender')}}</label>
            <div class="form-item-box">{{gender}}</div>
          </div>
          <div class="form-item">
            <label>{{$t('dob')}}</label>
            <div class="form-item-box">{{patient.dob}} ({{$t('age')}} {{age}})</div>
          </div>
        </div>
        <div class="formgroup grid-col-3">
          <div class="form-item">
            <label>{{$t('phone_number')}}</label>
            <div class="form-item-box">{{patient.mobile}}</div>
          </div>
          <div class="form-item grid-self-2">
            <label>{{$t('address')}}</label>
            <div class="form-item-box">{{patient.address}}</div>
          </div>
        </div>
        <div class="formgroup grid-col-1">
          <div class="form-item">
            <label>{{$t('contacted_orgs')}}</label>
            <div class="form-item-box">{{contacted}}</div>
          </div>
        </div>
        <h5>{{$t('family_information')}}</h5>
        <div class="formgroup grid-col-2">
          <div class="form-item">
            <label>{{$t('family_number')}}</label>
            <div class="form-item-box">{{patient.count_family}}</div>
            <small>คน</small>
          </div>
          <div class="form-item">
            <label>{{$t('covid_positive')}}</label>
            <div class="form-item-box">{{patient.count_covid}}</div>
            <small>คน</small>
          </div>
        </div>
        <h5>{{$t('health_coverage_information')}}</h5>
        <div class="formgroup grid-col-2">
          <div class="form-item">
            <label>{{$t('health_coverage')}}</label>
            <div class="form-item-box">{{health_coverage}}</div>
          </div>
          <div class="form-item">
            <label>{{$t('insurance_paper')}}</label>
            <div class="form-item-box">
              <img v-if="patient.insurance_image" :src="patient.insurance_image" height="50"/>
              <span v-else>-</span>
            </div>
          </div>
        </div><!-- formgroup -->

        <h5>{{$t('consent_form')}}</h5>
        <div class="formgroup grid-col-1">
          <div class="form-item">
            <label>{{$t('consent_form')}}</label>
            <div class="form-item-box">
              <a v-if="patient.consent_form" :href="patient.consent_form" target="_blank">Consent Form</a>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="field col md:col-2 lg:col-3 col-12">
        <div class="grid">
          <div class="col-6 md:col-12">
            <h6>{{$t('upload_profile_image')}}</h6>
            <div class="take-photo-wrapper">
              <img v-if="patient.profile_pic" :src="patient.profile_pic"/>
              <span v-else>-</span>
            </div>
          </div>
          <div class="col-6 md:col-12">
            <h6>{{$t('id_card_image')}}</h6>
            <div class="take-photo-wrapper">
              <img v-if="patient.cid_image" :src="patient.cid_image"/>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div><!--col-->
    </div><!--grid-->


    <h5>{{$t('ci_information')}}</h5>
    <div class="formgroup grid-col-4">
      <div class="form-item">
        <label>{{$t('hn')}}</label>
        <div class="form-item-box">{{patient.hn}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('type')}}</label>
        <div class="form-item-box"><span class="to_uppercase">{{patient.ward_type}}</span></div>
      </div>
      <div class="form-item">
        <label>{{$t('ward_zone')}}</label>
        <div class="form-item-box">{{patient.ward}}</div>
      </div>
        
      <div class="form-item" v-if="patient.bed">
        <label>{{$t('bed')}}</label>
        <div class="form-item-box">{{patient.bed}}</div>
      </div>
    </div>
      
    <h5>{{$t('covid_test')}}</h5>
    <div class="formgroup grid-col-3">
      <div class="form-item">
        <label>{{$t('covid_test_type')}}</label>
        <div class="form-item-box">{{covid_test}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('covid_test_date')}}</label>
        <div class="form-item-box">{{patient.covid_test_date}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('covid_test_image')}}</label>
        <div class="form-item-box">
          <img v-if="patient.covid_test_image" :src="patient.covid_test_image"  height="50"/>
          <span v-else>-</span>
        </div>
      </div>
      
    </div><!-- formgroup -->

    <div class="formgroup grid-col-3">
      <div class="form-item grid-self-2">
        <label>{{$t('symptom')}}</label>
        <div class="form-item-box">{{symptom}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('symptom_start_date')}}</label>
        <div class="form-item-box">{{patient.symptom_start_date}}</div>
      </div>
    </div>

    <div class="formgroup grid-col-3">
      <div class="form-item grid-self-2">
        <label>{{$t('cxr')}}</label>
        <div class="form-item-box">{{patient.cxr}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('test_date')}}</label>
        <div class="form-item-box">{{patient.cxr_date}}</div>
      </div>
    </div><!-- formgroup -->

    <div class="formgroup grid-col-3">
      <div class="form-item">
        <label>{{$t('spo2')}}</label>
        <div class="form-item-box">{{patient.spo2}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('pulse')}}</label>
        <div class="form-item-box">{{patient.pulse}}</div>
      </div>
      <div class="take-photo-wrapper">
        <img v-if="patient.spo2_pic" :src="patient.spo2_pic"  height="50"/>
        <span v-else>-</span>
      </div>
    </div><!-- formgroup -->

    <h5>{{$t('health_information')}}</h5>
    <div class="p-fluid grid">
      <div class="col">
        <div class="formgroup grid-col-4">
          <div class="form-item">
            <label>{{$t('temperature')}}</label>
            <div class="form-item-box">{{patient.temp}}</div>
            <small>&deg;C</small>
          </div>
          <div class="form-item">
            <label>{{$t('rr')}}</label>
            <div class="form-item-box">{{patient.rr}}</div>
          </div>
          <div class="form-item grid-self-2">
            <label>BP</label>
            <div class="form-item-box">{{patient.sbp}}</div>
            <small>/</small>
            <div class="form-item-box">{{patient.dbp}}</div>
          </div>
        </div><!-- formgroup -->
      </div>
    </div>
    <div class="formgroup grid-col-2">
      <div class="form-item">
        <label>{{$t('hx_allergy')}}</label>
        <div class="form-item-box">{{patient.hx_allergy}}</div>
      </div>
      <div class="form-item">
        <label>{{$t('hx_medication')}}</label>
        <div class="form-item-box">{{patient.hx_medication}}</div>
      </div>
    </div>

    <div class="formgroup grid-col-1">
      <div class="form-item">
        <label>{{$t('ud')}}</label>
        <div class="form-item-box">{{ud}}</div>
      </div>
    </div>

    <div class="formgroup grid-col-1">
      <div class="form-item">
        <label>{{$t('note')}}</label>
        <div class="form-item-box">{{patient.note}}</div>
      </div>
    </div><!-- formgroup -->

    <p class="text-right">
      <span class="p-button	p-button-text" @click="close">{{$t('close')}}</span>
    </p>
  </div>
</template>

<script>
import patient from '@/models/patient';
import { PatientService } from '@/services/patient';
export default {
  components: {
  },
  props: ['id'],
  emits: ['close'],
  data() {
    return {
      isReady: false,
      submitted: false,
      result: null,
      image: null,
      patient: {...patient},
      date: '',
      errors: [],
      initials: [
        "ไม่ระบุ","นาย","นาง","นางสาว","เด็กชาย","เด็กหญิง"
      ],
      genders:[
        {name: this.$t('male'), value: "M"},
        {name: this.$t('female'), value: "F"},
        {name: this.$t('unknown'), value: "U"}
      ],
      symptoms: [],
      test_types: [],
      uds: [],
      wards: [],
      beds: [],
      health_coverages: [],
      contacted_orgs: [],
      status_title: '',

      display: {}
    }
  },
  mounted() {
    PatientService.get_form(this.id)
      .then((res)=>{
        this.symptoms = res.symptoms.choices
        this.contacted_orgs = res.contacted_orgs.choices
        this.health_coverages = res.health_coverage
        this.test_types = [...[{'id':'','name':'None'}],...res.test_type]
        this.uds = res.uds.choices
        this.wards = res.wards
        if(this.wards.length===1){
          this.beds = this.wards[0].beds
        }
        if(res.registration){
          this.mapPatient(res.registration)
        }
        
        this.isReady = true
      },(res)=>{
        this.errors = res
      })
  },
  methods: {
    chageWard(e) {
      this.setWardBeds(e.value)
    },
    setWardBeds(ward_id) {
      if(!ward_id)return null
      this.wards.map((item)=>{
        if(item.id===ward_id) this.beds = item.beds
      })
      //console.log(this.beds)
    },
    close() {
      this.$emit('close')
    },
    spo2change(data) {
      if(this.isReady) {
        this.patient.spo2_image_id = data.image_id
        this.patient.spo2 = data.spo2
        this.patient.pulse = data.pulse
      }
    },
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    },
    mapPatient(data) {
      this.patient = {
        cid_image: data.cid_image,
        insurance_image: data.insurance_image,
        profile_pic: data.profile_pic,
        address: data.address,
        bed: data.bed,
        bed_id: data.bed_id,
        ward: data.ward,
        ward_id: data.ward_id,
        cid: data.cid,
        consent_form: data.consent_form_url,
        contacted_orgs: data.contacted_orgs.map((item)=>{return item.choice_id}),
        count_covid: data.count_covid,
        count_family: data.count_family,
        covid_test_type_id: data.covid_test_type_id,
        dob: data.dob,
        prefix: data.prefix,
        firstname: data.firstname,
        lastname: data.lastname,
        gender: data.gender,
        health_coverage: data.health_coverage,
        hn: data.hn,
        hx_allergy: data.hx_allergy,
        hx_medication: data.hx_medication,
        id: data.id,
        mobile: data.mobile,
        note: data.note,
        covid_test_date: data.covid_test_date,
        covid_test_image: data.covid_test_image,
        cxr: data.cxr,
        cxr_date: data.cxr_date,
        spo2_pic: data.spo2_image_url,
        symptom_start_date: data.symptom_start_date,
        symptoms: data.symptoms.map((item)=>{return item.choice_id}),
        uds: data.uds.map((item)=>{return item.choice_id}),
        sbp: data.sbp,
        dbp: data.dbp,
        spo2: data.spo2, 
        pulse: data.pulse,
        rr: data.rr,
        temp: data.temp,
        ward_type: data.ward_type
      }
      this.setWardBeds(data.ward_id)
    }
  },
  computed: {
    spo2data() {
      if(this.patient) {
        let res = { "spo2": this.patient.spo2, "pulse": this.patient.pulse }
        //console.log(res)
        return res
      } else {
        return null
      }
    },
    age() { // birthday is a date
      if(!this.patient.dob)return '-'
      let birthday = new Date(this.patient.dob)
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    contacted() {
      if(!this.patient || !this.patient.contacted_orgs)return ''
      let result = []
      this.contacted_orgs.map((item)=>{
        if(this.patient.contacted_orgs.indexOf(item.id) != -1) {
          result.push(item.title)
        }
      })
      return result.join(', ')
    },
    health_coverage() {
      let value = null
      this.health_coverages.map((item)=>{
        if(item.value===this.patient.health_coverage)
        value = item.text
      })
      return value
    },
    gender() {
      let value = null
      this.genders.map((item)=>{
        if(item.value===this.patient.gender)
        value = item.name
      })
      return value
    },
    covid_test() {
      let value = null
      this.test_types.map((item)=>{
        if(item.id===this.patient.covid_test_type_id)
        value = item.name
      })
      return value
    },
    symptom() {
      if(!this.patient || !this.patient.symptoms)return ''
      let result = []
      this.symptoms.map((item)=>{
        if(this.patient.symptoms.indexOf(item.id) != -1) {
          result.push(item.title)
        }
      })
      return result.join(', ')
    },
    ud() {
      if(!this.patient || !this.patient.uds)return ''
      let result = []
      this.uds.map((item)=>{
        if(this.patient.uds.indexOf(item.id) != -1) {
          result.push(item.title)
        }
      })
      return result.join(', ')
    },
  }
}
</script>

<style lang="scss" scoped>
.take-photo-wrapper {
  max-width:200px;
  margin: 0 auto;
}
.line-passcode {
  width: 350px;
  margin: 20px auto;
  background: var(--surface-a);
  color: var(--text-color);
  box-shadow: 0 2px 1px rgba(0,0,0,.2);
  border-radius: 3px;
  text-align:center;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  &>span {
    font-size: 1.3em;
    line-height: 1.3em;
  }
  &>strong {
    font-size: 3em;
    line-height: 1.3em;
  }
}
</style>