import axios from 'axios'
import authHeader from '@/helpers/auth_header'
//import unauthorized from '@/helpers/unauthorized'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER
const loginParameters = {"grant_type": "password","client_id": "1","client_secret": "fJ1iMb9Pj4knACKRKbcFdcGmTxhKFsQO5LT2VNeJ","scope": "*"} 

export const UserService = {
  login,
  logout
};

function login (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data, 
      ...loginParameters
    }
    axios({
      url: `${REST_ENDPOINT}login`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function logout () {
  axios({
    url: `${REST_ENDPOINT}logout`,
    method: 'GET',
    headers: authHeader()
  })
}