<template>
  <span v-if="triage" :style="'background:'+triage.color" :class="'triage-status-badge triage-size-'+size">
    {{triage.text}}
  </span>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  props: ['triage','size'],
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.triage-status-badge {
  padding: 0 5px;
  font-size: 0.9em;
  line-height: 1.4em;
  border-radius: 3px;
  font-weight: bold;
  color: #222;
  white-space: nowrap;
  &.triage-red {
    background: rgba($triage-red , 0.4);
  }
  &.triage-yellow {
    background: rgba($triage-yellow , 0.4);
  }
  &.triage-green {
    background: rgba($triage-green , 0.4);
  }
  &.triage-size-mini {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    font-size: 0;
    &.triage-red {
      background: rgba($triage-red , 1);
    }
    &.triage-yellow {
      background: rgba($triage-yellow , 1);
    }
    &.triage-green {
      background: rgba($triage-green , 1);
    }
  }
}
</style>