<template>
  <div class="content-container">
    <div class="grid">
      <div class="col"><h1>{{$t('patient_list')}}</h1></div>
      <div class="col text-right"><Button @click="$router.push('/main/register')" :label="$t('new_patient')" class="p-button-lg" icon="pi pi-plus" iconPos="right"/></div>
    </div>
    <div class="grid"> 
      <div class="col">
        <Dropdown v-model="selected_ward" :options="wards" optionValue="id" optionLabel="name" placeholder="Select Ward" style="min-width:150px" @change="changeWard"/>
        <template v-if="isReady">
          &nbsp;
          <Dropdown v-model="patient_filters['triage_code'].value" :options="triages" optionValue="id" optionLabel="text" placeholder="Triages" style="min-width:150px">
            <template #option="slotProps">
              <i class="triage-dot" :style="'background:'+slotProps.option.color"></i>&nbsp;&nbsp;<span>{{slotProps.option.text}}</span>
            </template>
          </Dropdown>
          &nbsp;
          <Dropdown v-model="patient_filters['type'].value" :options="types" optionValue="value" optionLabel="name" placeholder="Type" style="min-width:150px">
            <template #option="slotProps">
              {{slotProps.option.name}}
            </template>
          </Dropdown>
        </template>
      </div>
      <template v-if="isReady">
        <div class="col-1">
          <Button icon="pi pi-times" @click="clearPatientFilters" label="Clear" class="p-button-text" style="width:100%" />
        </div>
        <div class="col-3">
          <div class="p-inputgroup">
              <InputText placeholder="Search HN, Name, Phone" v-model="patient_filters.global.value"/>
              <Button icon="pi pi-search" />
          </div>
        </div>
      </template>
    </div>
    <template v-if="isReady">
      <DataTable :value="patients" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" 
        :paginator="true" :rows="20" selectionMode="single" dataKey="hn" @rowSelect="onRowSelect" 
        stateStorage="session" stateKey="chivid-state-patients"
        sortField="id" :sortOrder="-1" v-model:filters="patient_filters" :globalFilterFields="['bed','name','hn','phone']">
        <Column headerStyle="width: 50px;" bodyStyle="padding:0;line-height:0;width: 50px;" field="image">
          <template #body="slotProps">
            <img :src="slotProps.data.image" class="parient-image" />
          </template>
        </Column>
        <Column field="triage" :header="$t('color')" class="text-center">
          <template #body="slotProps">
            <TriageBadgeStatus size="mini" :triage="slotProps.data.triage" />
          </template>
        </Column>
        <Column field="ward" :header="$t('ward')" :sortable="true" class="text-center" style="min-width:100px"></Column>
        <Column field="bed" :header="$t('bed')" :sortable="true" class="text-center" style="min-width:50px"></Column>
        <Column field="hn" :header="$t('hn')" :sortable="true" class="text-center"  style="min-width:100px"></Column>
        <Column field="type" :header="$t('type')" :sortable="true" class="text-center" style="min-width:100px"></Column>
        <Column field="name" :header="$t('name')" :sortable="true" style="min-width:200px">
          <template #body="slotProps">
            <strong>{{slotProps.data.name}}</strong>
            <PatientTag :id="slotProps.data.id" :tags="slotProps.data.tags" :editable="false"/>
          </template>
        </Column>
        <Column field="age" :header="$t('age')" :sortable="true" class="text-center"></Column>
        <Column field="spo2.value" :header="$t('spo2')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.spo2"/>
          </template>
        </Column>
        <Column field="temp.value" :header="$t('temp')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.temp"/>
          </template>
        </Column>
        <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
        <Column field="last_round" headerStyle="width: 2em;" :header="$t('progress')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <small>{{timeDiff(slotProps.data.last_round)}}</small>
          </template>
        </Column>
        <!--Column field="last_progress_note" headerStyle="width: 2em;" :header="$t('last_progress_note')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <small>{{slotProps.data.last_progress_note}}</small>
          </template>
        </Column-->
        <!-- <Column field="covid_day" headerStyle="width: 2em;" :header="$t('covid_day')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <small>{{slotProps.data.covid_day}} {{$t('day')}}</small>
          </template>
        </Column> -->
        <Column field="admit_date" headerStyle="width: 2em;" :header="$t('admit')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <small>{{timeDiff(slotProps.data.admit_date)}}</small>
          </template>
        </Column>
        <template #empty>
          <div class="text-center">{{$t('no_record_found')}}</div>
        </template>
      </DataTable>
    </template>
    <div v-else-if="selected_ward===null">
      <h3 class="text-center">{{$t('please_select_ward')}}</h3>
    </div>
    <div class="page-loading" v-else>
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';

import InterpretationText from '@/components/InterpretationText';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import PatientTag from '@/components/PatientTag';

import { FilterMatchMode } from 'primevue/api';
import patient from '@/models/patient';
import { PatientService } from '@/services/patient';
import { EtcService } from '@/services/etc';
import timeDiff from '@/helpers/time_diff';

export default {
  components: {
    Button,Dropdown,InputText,DataTable,Column,InterpretationText,ProgressSpinner,TriageBadgeStatus,PatientTag
  },
  data() {
    return {
      isReady: false,
      response: null,
      selected_ward: null,
      filter_keyword: null,
      filter_triage: null,
      patient_filters: {},
      wards: [],
      triages: [],
      patients: [],
      types: [
        {name: 'All', value: ""},
        {name: 'Acute Care', value: "acute"},
        {name: 'Intermediate Care', value: "intermediate"},
        {name: 'Long Term Care', value: "long_term"},
        {name: 'Paliative Care', value: "paliative"}
      ]
    }
  },
  mounted() {
    this.initPatientFilters()
    EtcService.wards()
      .then((res)=>{
        if(res && res.list) {
          this.wards = this.initWards(res.list);
          this.triages = this.initTriages(res.triage);
        } else {
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
        }
      },(res)=>{
        this.errors = res
      })
    this.selected_ward = this.$store.state.app.ward
    if(this.selected_ward) {
      this.selectWard(this.selected_ward)
    }
  },
  methods: {
    timeDiff: timeDiff,
    changeWard(e) {
      this.selectWard(e.value)
      this.$store.dispatch('app/setWard',e.value)
    },
    selectWard(ward) {
      this.isReady = false
      PatientService.patients(ward)
        .then((res)=>{
          if(res) {
            this.response = res
            this.patients = this.initPatients();
            this.isReady = true
          } else {
            this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
          }
        },(res)=>{
          this.errors = res
        })
    },
    initPatients() {
      if(!this.response || !this.response.patients)return;
      let patients = []
      this.response.patients.map((item)=>{
        patients.push({
          "id": item.id,
          "image": item.profile_pic,
          "ward_id": item.ward_id,
          "ward": item.ward,
          "bed": item.bed,
          "gender": item.gender,
          "hn": item.hn,
          "phone": item.mobile,
          "name": item.fullname,
          "age": item.age,
          "covid_day": item.covid_day,
          "spo2": item.vital_signs.spo2,
          "pulse": item.vital_signs.pulse,
          "bp": item.vital_signs.bp,
          "temp": item.vital_signs.temp,
          "note": '',
          "important": false,
          "star": false,
          "type": item.type,
          "admit_date": item.admit_date,
          "triage": item.triage,
          "triage_code": item.triage.code,
          "last_round":item.last_round,
          "last_progress_note":item.last_progress_note,
          "tags": item.tags,
        });
      })
      return patients
    },
    initWards(ward_list) {
      let wards = []
      wards.push(
        { "id": 'all', "name": this.$t('all') },
        { "id": 'none', "name": this.$t('no_ward') },
      );
      ward_list.map((item)=>{
        wards.push({
          "id": item.id,
          "name": item.name
        });
      })
      return wards
    },
    initTriages(triage_list) {
      let triages = []
      triages.push(
        { "id": null, "text": this.$t('all'), "color": '#fff' },
      );
      triage_list.map((item)=>{
        triages.push({
          "id": item.code,
          "color": item.color,
          "text": item.text
        });
      })
      return triages
    },
    initPatientFilters() {
      this.patient_filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'triage_code': {value: null, matchMode: FilterMatchMode.EQUALS},
        'type': {value: null, matchMode: FilterMatchMode.EQUALS}
      }
    },
    clearPatientFilters() {
      this.initPatientFilters()
    },
    onRowSelect(event) {
      this.$router.push('/main/patient/'+event.data.id)
    },
  },
  computed: {
  }
}
</script>