<template>
  <div class="small-patient-info">
    <div class="small-patient-image">
      <img :src="patient.profile_pic" />
    </div>
    <div class="small-patient-content">
      <div class="small-patient-data">
        <span>{{$t('hn')}}</span>
        <strong>{{patient.hn}}</strong>
      </div>
      <div class="small-patient-data">
        <span>{{$t('name')}}</span>
        <strong>{{patient.fullname}}</strong>
      </div>
      <div class="small-patient-data">
        <span>{{$t('bed')}}</span>
        <strong>{{patient.ward}} {{patient.bed}}</strong>
      </div>
    </div>
    <div class="small-patient-triage">
      <TriageBadgeStatus :triage="patient.triage"/>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';

export default {
  components: {
    TriageBadgeStatus
  },
  data () {
    return {
    }
  },
  props: ['patient'],
  mounted() {
  }
}
</script>

<style lang="scss">
.small-patient-info {
  display:flex;
  align-items: stretch;
  background: var(--surface-a);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  margin: 5px 0 10px;
  border-radius: 3px;
  overflow: hidden;
  .small-patient-image {
    width: 80px;
    line-height: 0;
    img {
      width:100%;
    }
  }
  .small-patient-content {
    padding: 5px 10px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .small-patient-data {
    display: flex;
    line-height: 1.4em;
    margin: 0 30px 0 0;
    span {
      width:60px;
    }
    strong {

    }
  }
  .small-patient-round {
    border-left: 1px solid #eee;
    padding: 0 10px;
    font-weight: bold;
    display: flex;
    align-items:center;
  }
  .small-patient-triage {
    padding: 5px 2px;
    
  }
}
</style>