<template>
  <div class="content-container" v-if="isReady">
    <div class="grid">
      <div class="col-7">
        <h1>{{$t('fulfillments')}}</h1>
      </div>
      <div class="col-5">
        <div class="p-inputgroup">
          <InputText placeholder="Keyword"/>
          <Button icon="pi pi-search" />
        </div>
      </div>
    </div>

    <DataTable :value="list" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" :paginator="true" :rows="10"
    selectionMode="single" dataKey="id" @rowSelect="onRowSelect">
      <Column field="fullname" :header="$t('name')" :sortable="true"></Column>
      <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
      <Column field="address" :header="$t('address')" :sortable="true"></Column>
      <Column field="items_implode" :header="$t('item')" :sortable="true"></Column>
      <Column field="date" :header="$t('ordered')" :sortable="true"></Column>
      <Column field="status" headerStyle="width: 2em;" header="" :sortable="true" class="text-center">
        <template #header>
          <i class="pi pi-check"></i>
        </template>
        <template #body="slotProps">
          <Button :label="$t('process')" class="p-button-sm p-button-secondary" @click="markSent(slotProps.data)"/>
        </template>
      </Column>
      <template #empty>
        <div class="text-center">{{$t('no_record_found')}}</div>
      </template>
    </DataTable>
  </div>
  <div class="page-loading" v-else>
    <ProgressSpinner />
  </div>

  <Dialog :header="$t('item_sent')" :modal="true" v-model:visible="sent_dialog" class="medium-dialog">
    <div class="edit-order-items" v-if="sent_dialog">
      <table class="table text-center">
        <thead>
          <tr>
            <th></th>
            <th><label><RadioButton v-model="select_all" name="select_all" @click="checkAll" value="ordered"/> {{$t('ordered')}}</label></th>
            <th><label><RadioButton v-model="select_all" name="select_all" @click="checkAll" value="preparing"/> {{$t('preparing')}}</label></th>
            <th><label><RadioButton v-model="select_all" name="select_all" @click="checkAll" value="delivered"/> {{$t('sent')}}</label></th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="item in sent_dialog_order" :key="'order-item-'+item.orderable_id">
            <td class="text-left"><strong>{{item.name}}</strong></td>
            <td><label><RadioButton :name="'choice-'+item.orderable_id" value="ordered" v-model="sent_dialog_status[item.orderable_id]" /></label></td>
            <td><label><RadioButton :name="'choice-'+item.orderable_id" value="preparing" v-model="sent_dialog_status[item.orderable_id]" /></label></td>
            <td><label><RadioButton :name="'choice-'+item.orderable_id" value="delivered" v-model="sent_dialog_status[item.orderable_id]" /></label></td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <p class="text-right">
      <Button type="button" icon="pi pi-check" :label="$t('save_order')" iconPos="left" @click="doDeliveredOrder" />
    </p>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';

import ProgressSpinner from 'primevue/progressspinner';
import patient from '@/models/patient';
import { FulfillmentService } from '@/services/fulfillment';

export default {
  components: {
    Button,InputText,DataTable,Column,ProgressSpinner,Dialog,RadioButton
  },
  data() {
    return {
      isReady: false,
      response: [],
      sent_dialog: null,
      sent_dialog_order: [],
      sent_dialog_status: {},
      select_all: null
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      FulfillmentService.pending()
        .then((res)=>{
          if(res) {
            this.response = res
            this.isReady = true
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    markSent(selected) {
      this.sent_dialog_status = {}

      selected.items.map((item)=>{
        this.sent_dialog_status[item.orderable_id] = item.status
      })
      this.sent_dialog = selected.id
      this.sent_dialog_order = selected.items
    },
    doDeliveredOrder() {
      let data = []
      Object.keys(this.sent_dialog_status).map((key, index) => {
        data.push( {'orderable_id':key,'status':this.sent_dialog_status[key]} )
      });
      FulfillmentService.sent(this.sent_dialog,{orders:data})
        .then((res)=>{
          if(res) {
            this.loadList()
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
      this.select_all = null
      this.sent_dialog = null
      this.sent_dialog_order = []
      this.sent_dialog_status = {}
    }
  },
  computed : {
    list() {
      if(!this.response || !this.response.list)return;
      let list = []
      this.response.list.map((item)=>{
        if(this.filter_keyword) {
          let searchvals = [item.hn,item.fullname]
          searchvals = searchvals.join()
          if (searchvals.toLowerCase().indexOf(this.filter_keyword) < 0) {
            return
          }
        }
        list.push({
          "id": item.patient.id,
          "date": item.ordered_at,
          "fullname": item.patient.fullname,
          "phone": item.patient.mobile,
          "address": item.patient.address,
          "items": item.items,
          "items_implode": item.items_implode
        });
      })
      return list
    },
  },
  watch: {
    select_all(newval,oldval) {
      this.sent_dialog_order.map((item)=>{
        this.sent_dialog_status[item.orderable_id] = newval
      })
    }
  }
}
</script>

<style lang="scss">
</style>