import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const RequestTransferService = {
  pending,
  sent
};

function pending () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}pending-transfer`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function sent (pid,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+`patient/${pid}/order/delivered`,
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}