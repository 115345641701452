const AUTH_TOKEN_KEY = 'authToken'
const CURRENT_COMPANY = 'currentCompany'
const USER_PROFILE = 'userProfile'

const token = localStorage.getItem(AUTH_TOKEN_KEY)
const company = localStorage.getItem(CURRENT_COMPANY)
const profile = localStorage.getItem(USER_PROFILE)

const state = token ? { 
  loggedIn: true, 
  token: token ,
  profile: profile?JSON.parse(profile):null,
  company: company
} : { 
  loggedIn: false, 
  token: null ,
  profile: null,
  company: null
}

const actions = {
  login(context, token) {
    context.commit('login',token)
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  },
  logout(context) {
    context.commit('logout')
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(CURRENT_COMPANY)
    localStorage.removeItem(USER_PROFILE)
  },
  choose_company(context, company) {
    context.commit('company',company)
    localStorage.setItem(CURRENT_COMPANY, company)
  },
  profile(context, profile) {
    context.commit('profile',profile)
    localStorage.setItem(USER_PROFILE,  JSON.stringify(profile))
  },
}

const mutations = {
  login(state,token) {
    state.token = token
    state.loggedIn = true
  },
  logout(state) {
    state.token = null
    state.company = null
    state.loggedIn = false
  },
  choose_company(state,company){
    state.company = company
  },
  profile(state,profile) {
    state.profile = profile
  }
}

const getters = {
  loggedIn(state) {
    return state.loggedIn;
  },
  token(state) {
    return state.token;
  },
  company(state) {
    return state.company;
  },
  fullname(state) {
    return (state.profile && state.profile.fullname)?state.profile.fullname:null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}