<template>
  <div class="content-container">
    <h1>{{$t('edit_patient')}}</h1>
    <PatientForm :id="patient_id" action="edit" @done="formSubmitted" @back="goBack"/>
  </div>
</template>

<script>
import { PatientService } from '@/services/patient';
import PatientForm  from '@/components/PatientForm';
export default {
  components: {
    PatientForm
  },
  data() {
    return {
      patient_id: this.$route.params.id,
      submitted: false
    }
  },
  mounted() {
  },
  methods: {
    formSubmitted() {
      this.submitted = true
      this.goBack()
    },
    goBack() {
      this.$router.push('/main/patient/'+this.patient_id)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>