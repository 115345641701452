<template>
  <div class="app-header">
    <div class="app-header-logo">
      <text-button v-if="nav" icon="pi pi-bars" @click="toggleSidebar"></text-button>
      <h1 class="app-logo-text" @click="$router.push('/home')">
      <img :src="require(`@/assets/logo.png`)" width="24" /> 
      <span>Urban Medicine</span></h1>
    </div>
    <div class="app-header-content">
      <display-setting/>
      <user-setting/>
      <div class="locale-changer-wrapper">
        <div class="locale-changer">
          <span @click="toggleMenu">{{$i18n.locale}} <i class="pi pi-chevron-down"></i></span>
          <Menu class="locale-changer-menu" ref="actionmenu" :model="menu_items" :popup="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplaySetting from './DisplaySetting.vue';
import UserSetting from './UserSetting.vue';
import TextButton from '@/components/TextButton.vue';
import Menu from 'primevue/menu';
export default {
  components: {
    DisplaySetting,TextButton,UserSetting,Menu
  },
  data() {
    return {
      selected_locale: null
    }
  },
  props: ['nav'],
  methods: {
    toggleMenu() {
      this.$refs.actionmenu.toggle(event);
    },
    toggleSidebar() {
      this.$store.dispatch('app/toggleSidebarActive')
    },
    changeLocale() {
      this.$i18n.locale = this.selected_locale
      this.$store.dispatch('app/setLocale',this.selected_locale)
    }
  },
  mounted(){
    this.selected_locale = this.$i18n.locale = this.$store.state.app.locale
  },
  computed: {
    menu_items() {
      let langs = []
      this.$i18n.availableLocales.map((lang,i)=>{
        langs.push({
					label: lang,
					icon: 'pi pi-globe',
					command: () => { 
            this.selected_locale = lang
            this.changeLocale() 
          }
				})
      })
      return langs
    }
  }
}
</script>