export default {
  hn: null,
  section: null,
  bed: null,

  dob: null,
  gender: null,
  initial: null,
  firstname: null,
  lastname: null,

  covid_result: null,
  covid_test_type_id: null,

  spo2: null,
  sbp: null,
  dbp: null,
  pulse: null,
  temp: null,
  rr: null,
  symptoms: [],
  uds: [],

  cxr: null,
  note: null
}