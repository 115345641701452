<template>
  <div class="content-container" v-if="submitted===false">
    <div v-if="errors.length>0">
      <Message v-for="(msg,index) of errors" severity="error" :key="'error-'+index" :closable="false">{{msg}}</Message>
    </div>
    <form @submit.prevent="handleSubmit" v-if="isReady">
      <div class="p-fluid grid">
        <div class="col md:col-10  lg:col-9 col-12">
          <div class="formgroup grid-col-3">
            <div class="form-item">
              <label>{{$t('initial')}}</label>
              <div><Dropdown :editable="true" inputId="initial" v-model="patient.prefix" :options="initials" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('firstname')}} <span class="asterik">*</span></label>
              <div><InputText v-model="patient.firstname" type="text" required /></div>
            </div>
            <div class="form-item">
              <label>{{$t('lastname')}}</label>
              <div><InputText v-model="patient.lastname" type="text" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('citizen_id')}}</label>
              <div><InputText v-model="patient.cid" type="text" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('gender')}}</label>
              <div><Dropdown inputId="gender" v-model="patient.gender" :options="genders" optionValue="value" optionLabel="name"  /></div>
            </div>
            <div class="form-item">
              <label>{{$t('dob')}}</label>
              <div><Calendar v-model="patient.dob" :yearNavigator="true" :monthNavigator="true" yearRange="1900:2020" :showIcon="true" dateFormat="yy-mm-dd" @date-select="dobChanged" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('age')}}</label>
              <div><InputText v-model="patient.age" type="text" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('weight')}}</label>
              <div><InputText v-model="patient.wt" type="text" /></div>
            </div>
            <div class="form-item">
              <label>{{$t('height')}}</label>
              <div><InputText v-model="patient.ht" type="text" /></div>
            </div>
          </div>
          <div class="formgroup grid-col-3">
            <div class="form-item">
              <label>{{$t('phone_number')}}</label>
              <div><InputText v-model="patient.mobile" type="text" /></div>
            </div>
            <div class="form-item grid-self-2">
              <label>{{$t('address')}}</label>
              <div><InputText v-model="patient.address" type="text" /></div>
            </div>
          </div>
          <div class="formgroup grid-col-1">
            <div class="form-item">
              <label>{{$t('contacted_orgs')}}</label>
              <MultiSelect v-model="patient.contacted_orgs" :options="contacted_orgs" :filter="false" optionValue="id" optionLabel="title" display="chip"/>
            </div>
          </div>
          <h5>{{$t('family_information')}}</h5>
          <div class="formgroup grid-col-2">
            <div class="form-item">
              <label>{{$t('family_number')}}</label>
              <div>
                <InputNumber v-model="patient.count_family" mode="decimal" :min="patient.count_covid" :max="100" />
              </div>
              <small>คน</small>
            </div>
            <div class="form-item">
              <label>{{$t('covid_positive')}}</label>
              <div>
                <InputNumber v-model="patient.count_covid" mode="decimal" :min="0" :max="patient.count_family" />
              </div>
              <small>คน</small>
            </div>
          </div>
          <h5>{{$t('health_coverage_information')}}</h5>
          <div class="formgroup grid-col-2">
            <div class="form-item">
              <label>{{$t('health_coverage')}}</label>
              <!--MultiSelect v-model="patient.health_coverage" :options="health_coverages" :filter="true" optionValue="value" optionLabel="text" display="chip"/-->

              <Dropdown v-model="patient.health_coverage" :options="health_coverages" optionValue="value" optionLabel="text" :filter="false" />
            </div>
            <div class="form-item">
              <label>{{$t('insurance_paper')}}</label>
              <div class="form-item-box"><input type="file" ref="insurance_image" name="insurance_image"/></div>
            </div>
          </div><!-- formgroup -->

          <h5>{{$t('consent_form')}}</h5>
          <div class="formgroup grid-col-1">
            <div class="form-item">
              <label>{{$t('consent_form')}}</label>
              <div class="form-item-box"><input type="file" ref="consent_form" name="consent_form"/></div>
            </div>
          </div>
        </div>
        <div class="field col md:col-2 lg:col-3 col-12">
          <div class="grid">
            <div class="col-6 md:col-12">
              <h6>{{$t('upload_profile_image')}}</h6>
              <div class="take-photo-wrapper">
                <take-photo :camera="true" @change="profile_picChanged" :title="$t('drag_or_click_to_upload_profile_picture')" :placeholder="patient.profile_pic_url"/>
              </div>
            </div>
            <div class="col-6 md:col-12">
              <h6>{{$t('id_card_image')}}</h6>
              <div class="take-photo-wrapper">
                <take-photo :camera="false" @change="idCardChanged" :title="$t('upload_id_card')" :placeholder="patient.cid_image_url"/>
              </div>
            </div>
          </div>
        </div><!--col-->
      </div><!--grid-->


      <h5>{{$t('unit_information')}}</h5>
      <div class="formgroup grid-col-4">
        <div class="form-item">
          <label>{{$t('hn')}}</label>
          <div><InputText v-model="patient.hn" type="text" /></div>
        </div>

        <div class="form-item">
          <label>{{$t('type')}}</label>
          <div><Dropdown inputId="type" v-model="patient.type" :options="types" optionValue="value" optionLabel="name"  /></div>
        </div>

        <div class="form-item">
          <label>{{$t('type')}} Ward</label>
          <div><Dropdown inputId="ward_type" v-model="patient.ward_type" :options="ward_types" optionValue="value" optionLabel="name"  /></div>
        </div>
        <div class="form-item" v-if="patient.ward_type">
          <label>{{$t('ward_zone')}}</label>
          <Dropdown v-model="patient.ward_id" :options="ward_list" optionValue="id" optionLabel="name" :filter="true" filterPlaceholder="Find Ward or Zone" @change="chageWard" />
        </div>
          
        <div class="form-item" v-if="patient.ward_type==='ci' || patient.ward_type==='hospital'">
          <label>{{$t('bed')}}</label>
          <div class="form-item-box p-formgroup-inline" v-if="beds && beds.length===0" >
            {{$t('please_select_ward')}}
          </div>
          <Dropdown v-else v-model="patient.bed_id" :options="beds" optionValue="id" optionLabel="number" :filter="true" filterPlaceholder="Find Bed"/>
        </div>
      </div>
        
      <h5>{{$t('covid_test')}}</h5>
      <div class="formgroup grid-col-3">
        <div class="form-item">
          <label>{{$t('covid_test_type')}}</label>
          <Dropdown v-model="patient.covid_test_type_id" :options="test_types" optionValue="id" optionLabel="name" :filter="false" :placeholder="$t('covid_test_type')"/>
        </div>
        <div class="form-item">
          <label>{{$t('covid_test_date')}}</label>
          <div><Calendar v-model="patient.covid_test_date" :showIcon="true" dateFormat="yy-mm-dd" /></div>
        </div>
        <div class="form-item">
          <label>{{$t('covid_test_image')}}</label>
          <div class="form-item-box"><input type="file" ref="covid_test_image" name="covid_test_image"/></div>
        </div>
      </div><!-- formgroup -->

      <div class="formgroup grid-col-3">
        <div class="form-item grid-self-2">
          <label>{{$t('symptom')}}</label>
          <MultiSelect v-model="patient.symptoms" :options="symptoms" :filter="true" optionValue="id" optionLabel="title" display="chip"/>
        </div>
        <div class="form-item">
          <label>{{$t('symptom_start_date')}}</label>
          <div><Calendar v-model="patient.symptom_start_date" :showIcon="true" dateFormat="yy-mm-dd" /></div>
        </div>
      </div>
      <div  v-if="other_symptoms" class="formgroup grid-col-3">
        <div class="form-item grid-self-2">
          <label>{{$t('other_symptom')}}</label>
          <div><InputText v-model="patient.symptoms_note" type="text" :placeholder="$t('other_symptoms')" /></div>
        </div>
      </div>

      <div class="formgroup grid-col-3">
        <div class="form-item grid-self-2">
          <label>{{$t('cxr')}}</label>
          <div><InputText v-model="patient.cxr" type="text" /></div>
        </div>
        <div class="form-item">
          <label>{{$t('test_date')}}</label>
          <div><Calendar v-model="patient.cxr_date" :showIcon="true" dateFormat="yy-mm-dd" /></div>
        </div>
      </div><!-- formgroup -->


      <h5>{{$t('health_information')}}</h5>

      <div class="p-fluid grid">
        <div class="col">
          <Spo2Read :data="spo2data" @change="spo2change" :placeholder="patient.spo2_image_url"/>
        </div>
      </div>
      <div class="p-fluid grid">
        <div class="col">
          <div class="formgroup grid-col-4">
            <div class="form-item">
              <label>{{$t('temperature')}}</label>
              <div>
                <InputNumber v-model="patient.temp" mode="decimal" :min="0" :max="100" :minFractionDigits="2"  />
              </div>
              <small>&deg;C</small>
            </div>
            <div class="form-item">
              <label>{{$t('rr')}}</label>
              <div>
                <InputNumber v-model="patient.rr" mode="decimal" :min="0"  />
              </div>
            </div>
            <div class="form-item grid-self-2">
              <label>BP</label>
              <div>
                <InputNumber v-model="patient.sbp" mode="decimal" :min="0" placeholder="SBP" />
              </div>
              <small>/</small>
              <div>
                <InputNumber v-model="patient.dbp" mode="decimal" :min="0" placeholder="DBP" />
              </div>
            </div>
          </div><!-- formgroup -->
        </div>
      </div>
      <div class="formgroup grid-col-2">
        <div class="form-item">
          <label>{{$t('hx_allergy')}}</label>
          <div><InputText v-model="patient.hx_allergy" type="text" /></div>
        </div>
        <div class="form-item">
          <label>{{$t('hx_medication')}}</label>
          <div><InputText v-model="patient.hx_medication" type="text" /></div>
        </div>
      </div>

      <div class="formgroup grid-col-1">
        <div class="form-item">
          <label>{{$t('ud')}}</label>
          <MultiSelect v-model="patient.uds" :options="uds" :filter="true" optionValue="id" optionLabel="title" display="chip"/>
        </div>
      </div>
      <div  v-if="other_uds" class="formgroup grid-col-1">
        <div class="form-item">
          <label>{{$t('other_uds')}}</label>
          <div><InputText v-model="patient.uds_note" type="text" :placeholder="$t('other_uds')" /></div>
        </div>
      </div>

      <div class="formgroup grid-col-1">
        <div class="form-item">
          <label>{{$t('note')}}</label>
          <div><InputText v-model="patient.note" type="text" /></div>
        </div>
      </div><!-- formgroup -->

      <p class="text-right">
        <span class="p-button	p-button-text" @click="goBack">{{$t('cancel')}}</span>&nbsp;
        <Button v-if="action==='register'" type="submit" :label="$t('add_patient')"  icon="pi pi-check" iconPos="right"/>
        <template v-else-if="action==='approve'">
          <Button type="submit" class="p-button-outlined"  :label="$t('edit')"  icon="pi pi-save" iconPos="right"/>
          &nbsp;
          <Button type="submit" :label="$t('approve')" @click="patient.is_admitted=true"  icon="pi pi-check" iconPos="right"/>
        </template>
        <template v-else-if="action==='add_waiting'">
          <Button type="submit" :label="$t('add_patient')"  icon="pi pi-check" iconPos="right"/>
        </template>
        <Button v-else type="submit" :label="$t('edit_patient')"  icon="pi pi-check" iconPos="right"/>
      </p>
    </form>
  </div>
  <div class="content-container" v-else>
    <SmallPatientCard :patient="result.patient"/>
    <h2 class="text-center">{{$t('save_successfully')}}</h2>

    <div class="register-info-cards">
      <Card v-if="result.patient && result.patient.admit_date">
        <template #content>
          <p>{{$t('order_medicine_and_equiptment')}}</p>
          <Button class="btn-block" @click="dialog_doctor_order=true">{{$t('doctor_order')}}</Button>
        </template>
      </Card>

      <Card  v-if="result.line_passcode">
        <template #content>
          <div class="line-passcode" v-if="result.line_passcode">
            <span>{{$t('line_passcode')}}</span>
            <strong>{{result.line_passcode}}</strong>
          </div>
        </template>
      </Card>
    </div>

    <br/>
    <h4 class="text-center" @click="goBack">{{$t('go_back')}}</h4>
  </div>

  <Dialog :header="$t('doctor_order')" :modal="true" v-model:visible="dialog_doctor_order" class="medium-dialog">
    <DoctorHiOrder v-if="dialog_doctor_order" :orders="orders" :pid="result.patient.id" @change="orderChanged"/>
  </Dialog>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import SmallPatientCard  from '@/components/SmallPatientCard';
import MultiSelect from 'primevue/multiselect';
import Spo2Read from '@/components/Spo2Read';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Card from 'primevue/card';

import patient from '@/models/patient';
import TakePhoto from '@/components/TakePhoto';
import DoctorHiOrder from '@/components/DoctorHiOrder';

import { PatientService } from '@/services/patient';
export default {
  components: {
    InputText,Calendar,Dropdown,TakePhoto,Button,Message,SmallPatientCard,MultiSelect,Spo2Read,InputNumber,DoctorHiOrder,Dialog,Card
  },
  props: ['id','action'],
  emits: ['done','cancel'],
  data() {
    return {
      approve: 'create',
      isReady: false,
      submitted: false,
      result: null,
      image: null,
      patient: {...patient},
      date: '',
      orders: [],
      errors: [],
      initials: [
        "ไม่ระบุ","นาย","นาง","นางสาว","เด็กชาย","เด็กหญิง"
      ],
      genders:[
        {name: this.$t('male'), value: "M"},
        {name: this.$t('female'), value: "F"},
        {name: this.$t('unknown'), value: "U"}
      ],
      ward_types: [
        {name: this.$t('community_isolation'), value: "ci"},
        {name: this.$t('home_isolation'), value: "hi"},
        {name: this.$t('hospital_ward'), value: "hospital"}
      ],
      types: [
        {name: 'Acute Care', value: "acute"},
        {name: 'Intermediate Care', value: "intermediate"},
        {name: 'Long Term Care', value: "long_term"},
        {name: 'Paliative Care', value: "paliative"}
      ],
      symptoms: [],
      test_types: [],
      uds: [],
      wards: [],
      beds: [],
      health_coverages: [],
      contacted_orgs: [],

      status_title: '',
      dialog_doctor_order: false,
      submitting: false
    }
  },
  mounted() {
    PatientService.get_form(this.id)
      .then((res)=>{
        this.symptoms = res.symptoms.choices
        this.contacted_orgs = res.contacted_orgs.choices
        this.health_coverages = res.health_coverage
        this.test_types = [...[{'id':'','name':'None'}],...res.test_type]
        this.uds = res.uds.choices
        this.wards = res.wards
        if(this.wards.length===1){
          this.beds = this.wards[0].beds
        }
        if(res.registration){
          this.mapPatient(res.registration)
        }
        this.isReady = true
      },(res)=>{
        this.errors = res
      })
  },
  methods: {
    profile_picChanged(data){
      this.profile_pic = data
    },
    idCardChanged(data) {
      this.cid_image = data
    },
    chageWard(e) {
      this.setWardBeds(e.value)
    },
    setWardBeds(ward_id) {
      if(!ward_id)return null
      this.wards.map((item)=>{
        if(item.id===ward_id) this.beds = item.beds
      })
      //console.log(this.beds)
    },
    goBack() {
      this.$emit('back')
    },
    orderChanged() {
      this.dialog_doctor_order = false
    },
    dobChanged(value) {
      if(!this.patient.dob)return;
      let birthday = new Date(this.patient.dob)
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.patient.age = Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    handleSubmit(){
      if(this.submitting)return
      this.$store.dispatch('app/showLoading')
      this.submitting = true
      let data = new FormData()

      if(this.patient.age && !this.patient.dob) {
        var d = new Date();
        var n = d.getFullYear();
        let calcDob = n - this.patient.age
        this.patient.dob = calcDob+'-01-01';
      }
      if(this.profile_pic)data.append('profile_pic', this.profile_pic);
      if(this.cid_image)data.append('cid_image', this.cid_image);
      if(this.$refs.insurance_image && this.$refs.insurance_image.files && this.$refs.insurance_image.files[0]) {
        data.append('insurance_image', this.$refs.insurance_image.files[0]);
      }
      if(this.$refs.covid_test_image && this.$refs.covid_test_image.files && this.$refs.covid_test_image.files[0]) {
        data.append('covid_test_image', this.$refs.covid_test_image.files[0]);
      }
      if(this.$refs.consent_form && this.$refs.consent_form.files && this.$refs.consent_form.files[0]) {
        data.append('consent_form', this.$refs.consent_form.files[0]);
      }
    
      if(this.action==='register')this.patient.is_admitted = 1
      Object.keys(this.patient).map((k, index) => {
        if(this.patient[k]==='' || this.patient[k]===null) {
          //null
        } else if(this.patient[k] instanceof Date && !isNaN(this.patient[k].valueOf())) {
          data.append(k,this.formatDate(this.patient[k]))
        } else if( this.patient[k] instanceof Array ) {
          this.patient[k].map((val)=>{
            data.append(k+'[]',val)
          })
        } else {
          data.append(k,this.patient[k])
        }
      })
      
      PatientService.save_patient(data,this.id)
        .then((res)=>{
          this.result = res
          this.submitted = true
          this.orders = res.orders
          this.$emit('done')
          this.submitting = false
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.errors = res
          this.submitting = false
          this.$store.dispatch('app/hideLoading')
        })
      
    },
    spo2change(data) {
      if(data.image_id)this.patient.spo2_image_id = data.image_id
      else this.patient.spo2_image_id = null
      this.patient.spo2 = data.spo2
      this.patient.pulse = data.pulse
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    },
    mapPatient(data) {
      this.patient = {
        cid_image: "",
        insurance_image: "",
        profile_pic: "",
        address: data.address,
        bed: data.bed,
        bed_id: data.bed_id,
        ward: data.ward,
        ward_id: data.ward_id,
        cid: data.cid,
        contacted_orgs: data.contacted_orgs.map((item)=>{return item.choice_id}),
        count_covid: data.count_covid,
        count_family: data.count_family,
        covid_test_type_id: data.covid_test_type_id,
        dob: data.dob,
        prefix: data.prefix,
        firstname: data.firstname,
        lastname: data.lastname,
        gender: data.gender,
        age: data.age,
        wt: data.wt,
        ht: data.ht,
        health_coverage: data.health_coverage,
        hn: data.hn,
        hx_allergy: data.hx_allergy,
        hx_medication: data.hx_medication,
        id: data.id,
        mobile: data.mobile,
        note: data.note,
        symptom_start_date: data.symptom_start_date,
        covid_test_date: data.covid_test_date,
        cxr: data.cxr,
        cxr_date: data.cxr_date,
        sbp: data.sbp,
        dbp: data.dbp,
        spo2: data.spo2, 
        pulse: data.pulse,
        rr: data.rr,
        temp: data.temp,
        ward_type: data.ward_type,
        type: data.type,
        symptoms: [],
        uds: [],
        spo2_image_url:data.spo2_image_url,
        profile_pic_url: data.profile_pic,
        cid_image_url: data.cid_image
      }

      if(data.symptoms){
        data.symptoms.map((item)=>{
          this.patient.symptoms.push(item.choice_id)
          if(item.has_text)this.patient.symptoms_note = item.value
        })
      }
      if(data.uds){
        data.uds.map((item)=>{
          this.patient.uds.push(item.choice_id)
          if(item.has_text)this.patient.uds_note = item.value
        })
      }
      this.setWardBeds(data.ward_id)
    }
  },
  computed: {
    other_symptoms() {
      let result = false
      if(this.patient.symptoms) {
        this.patient.symptoms.map((item)=>{
          this.symptoms.map((sym)=>{
            if(sym.id === item && sym.has_text) {
              result = true
            }
          })
        })
      }
      return result
    },
    other_uds() {
      let result = false
      if(this.patient.uds) {
        this.patient.uds.map((item)=>{
          this.uds.map((ud)=>{
            if(ud.id === item && ud.has_text) {
              result = true
            }
          })
        })
      }
      return result
    },
    ward_list() {
      let list = []
      this.wards.map((item)=>{
        if(this.patient.ward_type===item.type)list.push(item)
      })
      return list
    },
    spo2data() {
      if(this.patient) {
        let res = { "spo2": this.patient.spo2, "pulse": this.patient.pulse }
        //console.log(res)
        return res
      } else {
        return null
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.take-photo-wrapper {
  max-width:200px;
  margin: 0 auto;
}
.register-info-cards {
  display: flex;
  .p-card {
    margin: 10px;
    flex: 1;
    text-align: center;
  }
}
</style>