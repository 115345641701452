<template>
  <div class="line-passcode-wrapper">
    <div class="line-passcode" v-if="passcode">
      <span>{{$t('line_passcode')}}</span>
      <strong>{{passcode}}</strong>
    </div>
    <div v-else>
      <h4 class="text-center">Generating passcode...</h4>
    </div>
  </div>
</template>

<script>
import { PatientService } from '@/services/patient';
export default {
  components: {
  },
  data () {
    return {
      passcode: null,
      loading: true
    }
  },
  props: ['pid'],
  emits: ['close'],
  mounted() {
    PatientService.passcode(this.pid)
      .then((res)=>{
        this.loading = false
        this.passcode = res.line_passcode
      },(res)=>{
        this.loading = false
        this.errors = res
      })
  },
  methods: {
    goBack() {
      this.$emit('close')
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
</style>