<template>
  <div class="login">
    <Card>
      <template #content>
        <p class="text-center"><img :src="require(`@/assets/logo.png`)" width="100" /></p>
        <h3 class="text-center">Login to Urban Medicine</h3>
        <div v-if="errors.length>0">
          <Message v-for="(msg,index) of errors" severity="error" :key="'error-'+index" :closable="false">{{msg}}</Message>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="field p-fluid p-mb-2">
            <label for="username">Username</label>
            <InputText id="username" v-model="username" type="username" :class="invalidate('username')" />
          </div>
          <div class="field p-fluid p-mb-2">
            <label for="password">Password</label>
            <InputText id="password" v-model="password" type="password" :class="invalidate('password')" />
          </div>
          
          <p class="text-right p-mb-0"><Button type="submit" icon="pi pi-check" label="Login" /></p>
        </form>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import { UserService } from '@/services/user';

export default {
  name: 'Home',
  components: {
    Card,Button,InputText,Message
  },
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      errors: []
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      username: { required },
      password: { required }
    }
  },
  methods: {
    handleSubmit() {
      const isFormValid = !this.v$.$invalid
      this.submitted = true;
      if (!isFormValid) {
        return;
      }
      
      let data = {
        username: this.username,
        password: this.password
      }
      UserService.login(data)
        .then((res)=>{
          if(res.token.access_token) {
            this.$store.dispatch('user/login',res.token.access_token)
            this.loading = false

            let path = this.$route.query.redirect
            if(!path)path="/home"
            this.$router.push(path)

            if(res.fullname) {
              this.$store.dispatch('user/profile',{fullname:res.fullname})
            }
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    invalidate(name) {
      return (this.submitted && this.v$[name] && this.v$[name].$invalid)?'p-invalid':'';
    }
  },
  computed : {
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-color: var(--primary-color);

  .p-card {
    width: 90%;
    max-width: 350px;
  }
}
</style>
