<template>
  <div class="patient-action">
    <Button :label="$t('telemed')" icon="pi pi-phone" iconPos="left" class="p-button-outlined" style="width:30%;margin-right:2%;"  @click="doTelemed()" />
    <Button type="button" :label="$t('view_detail')" @click="actionToggle('view_patient')" style="width:30%;margin-right:2%;" class=" p-button-outlined" icon="pi pi-eye" iconPos="left" />
    <Button type="button" :label="$t('patient_action')" @click="toggleMenu" style="width:34%" class=" p-button-outlined" icon="pi pi-ellipsis-h" iconPos="right" />
    <Menu ref="actionmenu" :model="menu_items" :popup="true" />
  </div>
  <div class="patient-cards" v-if="patient">
    <Card>
      <template #content>
        <div class="grid ">
          <div class="col-4 md:col-3 p-pb-0">
            <div class="patient-avatar">
              <img :src="patient.profile_pic" />
            </div>
          </div>
          <div class="col">
            <div class="grid flex-row-reverse">
              <div class="col-12 md:col-4 text-right">
                <triage-badge-status :triage="patient.triage"/>
              </div>
              <div class="col">
                <h4 class="mb-0">{{patient.fullname}}</h4> 
              </div>
            </div>
            <div class="key-value-list-view">
              <div class="key-value-view" v-for="(info,index) in patient.info" :key="'patient-info-'+index">
                <span>{{info.text}}</span>
                <strong>{{info.value}}</strong>
              </div>
            </div>
            <PatientTag :id="data.patient.id" :tags="data.patient.tags" :editable="data.patient.status=='admit'" @change="patientChanged"/>
          </div>
        </div>
      </template>
    </Card>
    <div>
      <Card>
        <template #content>
          <ul class="order-items">
            <li v-for="order in data.orders" :key="'show-order-'+order.code">
              <strong>
                <i class="pi pi-check" v-if="order.status==='delivered'"></i>
                <i class="pi pi-clock" v-else-if="order.status==='ordered'"></i>
                <i class="pi pi-times" v-else-if="order.status==='cancelled'"></i>
                <i class="pi pi-minus" v-else></i>
                {{order.title}}
              </strong>
              <time>
                <small v-if="order.status==='delivered'">{{$t('received_at')}}</small>
                <small v-else-if="order.status==='ordered'">{{$t('ordered_at')}}</small>
                <small v-else-if="order.status==='cancelled'">{{$t('cancelled')}}</small>
                <small v-else>{{$t('no_order')}}</small>
                {{timeDiff(order.updated_at)}}
              </time>
            </li>
          </ul>
          <Button type="button" icon="pi pi-cog" :label="$t('edit_order')" class="p-button-text p-button-sm" iconPos="right" @click="actionToggle('doctor_order')" style="width:100%" v-if="data.patient.status=='admit'"/>
        </template>
      </Card>
    </div>
  </div>

  <Dialog :header="$t('view_patient')" :modal="true" v-model:visible="dialog.view_patient" class="large-dialog">
    <PatientProfile :id="patient.id"  @close="dialog.view_patient=null" />
  </Dialog>

  <Dialog :header="$t('notify_doctor')" :modal="true" v-model:visible="dialog.notify_doctor" class="medium-dialog">
    <h5>{{$t('notify_doctor_reason')}}</h5>
    <Textarea v-model="notify_doctor_note" :autoResize="true" rows="3" style="width:100%" />
    <p class="text-right">
      <Button type="button" icon="pi pi-bell" :label="$t('send_notification_to_doctors')" iconPos="left" @click="doNotifyDoctor" />
    </p>
  </Dialog>

  <Dialog :header="$t('discharge')" :modal="true" v-model:visible="dialog.discharge" class="medium-dialog">
    <div class="formgroup grid-col-1">
      <div class="form-item">
        <label>{{$t('discharge_reason')}}</label>
        <div><Dropdown inputId="gender" v-model="discharge_reason" :options="data.discharge_dispositions" 
        optionValue="code" optionLabel="title"  /></div>
      </div>
      <div class="form-item">
        <label>{{$t('discharge_date_time')}}</label>
        <div><Calendar v-model="discharge_date" :showTime="true" :hideOnDateTimeSelect="true" :showButtonBar="true" hourFormat="24" :showIcon="true" dateFormat="yy-mm-dd " /></div>
      </div>
    </div>
    <h5>{{$t('discharge_patient_note')}}</h5>
    <Textarea v-model="discharge_note" :autoResize="true" rows="3" style="width:100%" />
    <p class="text-right">
      <Button type="button" icon="pi pi-sign-out" :label="$t('do_discharge')" iconPos="left" @click="doDischarge" />
    </p>
  </Dialog>

  <Dialog :header="$t('transfer_patient')" :modal="true" v-model:visible="dialog.transfer_patient" class="medium-dialog">
    <PatientTransfer :pid="patient.id" @change="transferChanged" />
  </Dialog>

  <Dialog :header="$t('doctor_order')" :modal="true" v-model:visible="dialog.doctor_order" class="medium-dialog">
    <DoctorHiOrder v-if="dialog.doctor_order" :orders="data.orders" :pid="patient.id" @change="orderChanged"/>
  </Dialog>

  <Dialog :header="$t('line_passcode')" :modal="true" v-model:visible="dialog.line_passcode" class="small-dialog">
    <PatientLinePasscode :pid="patient.id" />
  </Dialog>
  
  <Dialog :modal="true" header="Patient" v-model:visible="dialog.edit_patient" class="large-dialog" @hide="patientEditClose">
    <PatientForm :id="patient.id" action="edit" @done="patientChanged" @back="patientEditClose"/>
  </Dialog>

  <Dialog :modal="true" header="Telemed" v-model:visible="telemedShow" class="medium-dialog" @hide="telemedShow=false">
    <div class="text-center">
      <p>
        <strong>คัดลอกลิงค์เพื่อส่งให้คนไข้ <span v-if="copied"> (คัดลอกแล้ว!)</span></strong><br/>
        <Button type="button" class=" p-button-outlined" icon="pi pi-copy" label="คัดลอก (Copy Link)" iconPos="left" @click="copyToClipboard(telemed.url)" />
      </p>

      <p><a :href="telemed.url" class="p-button p-component" target="_blank">
        <span class="pi pi-phone p-button-icon"></span> <span class="p-button-label"> เข้าสู่ระบบการให้บริการ Telemed</span>
        <span class="p-ink"></span>
      </a></p>
    </div>
  </Dialog>

  <Dialog :header="$t('sign_medical_certificate')" :modal="true" v-model:visible="dialog.doctor_sign" class="small-dialog">
    <h5>{{$t('confirm_sign_medical_certificate')}}</h5>
    <p class="text-right">
      <Button type="button" icon="pi pi-pencil" :label="$t('sign_medical_certificate')" iconPos="left" @click="doDoctorSign" />
    </p>
  </Dialog>
</template>

<script>
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import timeDiff from '@/helpers/time_diff';
import Dropdown from 'primevue/dropdown';

import DoctorHiOrder  from '@/components/DoctorHiOrder';
import PatientForm  from '@/components/PatientForm';
import PatientTag from '@/components/PatientTag';
import PatientProfile from '@/components/PatientProfile';
import PatientLinePasscode from '@/components/PatientLinePasscode';

import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import PatientTransfer from '@/components/PatientTransfer';
import { PatientService } from '@/services/patient';

export default {
  components: {
    Card,TriageBadgeStatus,Dialog,Button,Menu,Calendar,Dropdown,Textarea,PatientTag,PatientTransfer,PatientProfile,PatientLinePasscode,PatientForm,DoctorHiOrder
  },
  data () {
    return {
      action: null,
      menu_items: [],
      dialog: {
        view_patient: false,
        notify_doctor: false,
        discharge: false,
        transfer_patient: false,
        doctor_order: false,
        doctor_sign: false
      },
      patient: {},
      notify_doctor_note: null,
      discharge_date: null,
      discharge_reason: null,
      discharge_note: null,
      loading: false,
      telemed: null,
      telemedShow: false,
      copied:false
    }
  },
  props: ['data'],
  emits: ['change'],
  watch: { 
    data: function(newVal) {
      this.mapData(newVal)
    }
  },
  mounted() {
    this.mapData(this.data)

    if (this.patient && this.patient.status==='admit') {
      this.menu_items = [
        {
					label: this.$t('notify_doctor'),
					icon: 'pi pi-bell',
					command: () => { this.actionToggle('notify_doctor') }
				},
        { separator:true },
        {
          label: this.$t('transfer_patient'),
          icon: 'pi pi-reply',
          command: () => { this.actionToggle('transfer_patient') }
        },
        { separator:true },
        {
          label: this.$t('discharge'),
          icon: 'pi pi-sign-out',
          command: () => { this.actionToggle('discharge') }
        },
        { separator:true },
        {
          label: this.$t('line_passcode'),
          icon: 'pi pi-key',
          command: () => { this.actionToggle('passcode') }
        },
        { separator:true },
			]
    }
    if (this.data.user.role && (this.data.user.role==='doctor' || this.data.user.role==='admin')) {
      this.menu_items.push(
        {
          label: this.$t('sign_medical_Certification'),
          icon: 'pi pi-pencil',
          command: () => { this.actionToggle('sign') }
        },
      )
    }
    this.menu_items.push(
      { separator:true },
      {
        label: this.$t('edit_patient'),
        icon: 'pi pi-pencil',
        command: () => { this.actionToggle('edit_patient') }
      },
      { separator:true },
      {
        label: this.$t('print_medical_Certification'),
        icon: 'pi pi-print',
        command: () => { this.actionToggle('print_cert') }
      },
      {
        label: this.$t('print_admission_note'),
        icon: 'pi pi-print',
        command: () => { this.actionToggle('print_admission') }
      },
      {
        label: this.$t('print_medical_file'),
        icon: 'pi pi-print',
        command: () => { this.actionToggle('print') }
      }
    )
  },
  methods: {
    timeDiff: timeDiff,
    mapData(data) {
      this.patient = data.patient
    },
    toggleMenu() {
      this.$refs.actionmenu.toggle(event);
    },
    actionToggle(action) {
      if(action==='notify_doctor') {
        this.dialog.notify_doctor = true
      } else if(action==='discharge') {
        this.dialog.discharge = true
      } else if(action==='transfer_patient') {
        this.dialog.transfer_patient = true
      } else if (action==='doctor_order') {
        this.dialog.doctor_order = true
      } else if (action==='view_patient') {
        this.dialog.view_patient = true
      } else if (action==='passcode') {
        this.dialog.line_passcode = true
      } else if (action==='edit_patient') {
        this.dialog.edit_patient = true
      } else if (action==='print') {
        this.print()
      } else if (action==='print_admission') {
        this.print_admission()
      } else if (action==='print_cert') {
        this.print_cert()
      } else if (action==='sign') {
        this.dialog.doctor_sign = true
      }
    },
    doNotifyDoctor() {
      PatientService.notify_doctor(this.patient.id,{note:this.notify_doctor_note})
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
            this.notify_doctor_note = null
          }
        },(res)=>{
          this.loading = false
          this.errors = res
        })
      this.dialog.notify_doctor = false
    },
    doDoctorSign() {
      this.$store.dispatch('app/showLoading',this.$t('signing_medical_certification'))
      PatientService.doctor_sign(this.patient.id)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('sign_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
      this.dialog.doctor_sign = false
    },
    doDischarge() {
      this.sendDischarge(this.discharge_date,this.discharge_reason,this.discharge_note)
      this.dialog.discharge = false
    },
    orderChanged() {
      this.dialog.doctor_order = false
      this.$emit('change')
    },
    sendDischarge(datetime,disposition,note) {
      let data = {
        datetime: datetime,
        discharge_disposition_code: disposition,
        note: note
      }
      PatientService.discharge(this.patient.id,data)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.discharge_date = null
          this.discharge_reason = null
          this.discharge_note = null
          this.$router.push('/main/dashboard')
        },(res)=>{
          this.loading = false
          this.errors = res
        })
    },
    patientChanged() {
      this.$emit('change')
      this.dialog.edit_patient = false
    },
    transferChanged() {
      this.dialog.transfer_patient = false
      this.$emit('change')
    },
    patientEditClose() {
      this.dialog.edit_patient = false
      this.$emit('change')
    },
    print() {
      this.$store.dispatch('app/showLoading',this.$t('creating_pdf_file'))
      PatientService.print(this.$route.params.id)
        .then((res)=>{
          var fileURL = window.URL.createObjectURL(res);
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'CHIVID-Patient-'+this.$route.params.id+'.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
          
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.$store.dispatch('app/hideLoading')
          let error = 'Error Creating PDF File'
          if(res && res[0])error = res[0]
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail: error, life: 3000});
        })
    },
    print_admission() {
      this.$store.dispatch('app/showLoading',this.$t('creating_admission_note'))
      PatientService.print_admission(this.$route.params.id)
        .then((res)=>{
          var fileURL = window.URL.createObjectURL(res);
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'CHIVID-Patient-Admission-'+this.$route.params.id+'.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
          
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.$store.dispatch('app/hideLoading')
          let error = 'Error Creating PDF File'
          if(res && res[0])error = res[0]
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail: error, life: 3000});
        })
    },
    print_cert() {
      this.$store.dispatch('app/showLoading',this.$t('creating_pdf_file'))
      PatientService.print_cert(this.$route.params.id)
        .then((res)=>{
          var fileURL = window.URL.createObjectURL(res);
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'CHIVID-Patient-Cert-'+this.$route.params.id+'.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
          
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.$store.dispatch('app/hideLoading')
          let error = 'ไม่พบเอกสาร หรือ เอกสารดังกล่าวยังไม่ได้เซ็น'
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:error, life: 3000});
        })
    },
    doTelemed() {
      PatientService.telemed(this.patient.id)
        .then((res)=>{
          this.telemed = res
          this.telemedShow = true
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.$store.dispatch('app/hideLoading')
          let error = 'Error Making The Call'
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail: error, life: 3000});
        })
      console.log(this.patient)
    },
    copyToClipboard(text) {
      if(navigator.clipboard!==undefined) {
        navigator.clipboard.writeText(text);
        this.copied = true;
      } else if (window.clipboardData && window.clipboardData.setData) {
        window.clipboardData.setData("Text", text);
        this.copied = true;
      } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand("copy");
          this.copied = true;
        }
        catch (ex) {
          prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
          document.body.removeChild(textarea);
        }
      }
      if(this.copied) {
        setTimeout(()=>{this.copied=false;},1000)
      }
    },
  }
}
</script>

<style lang="scss">
.patient-cards {
  clear:both;
  display: grid;
  gap: 20px;
  grid-template-columns: 3fr 1fr;
  margin: 0 0 20px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .patient-avatar {
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    line-height: 0;
    margin: 0 0 10px;
    img {
      width:100%;
    }
  }
}
.patient-action {
  background: var(--surface-a);
  margin: 0 0 10px;
  width: 60vw;
  min-width: 300px;
  float:right;
  text-align: right;
  .p-dropdown {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
</style>