<template>
  <div class="content-container" v-if="isReady">
    <div class="grid">
      <div class="col-7">
        <h1>{{$t('transfer_request')}}</h1>
      </div>
      <div class="col-5">
        <div class="p-inputgroup">
          <InputText placeholder="Keyword"/>
          <Button icon="pi pi-search" />
        </div>
      </div>
    </div>

    <DataTable :value="list" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" :paginator="true" :rows="10"
    selectionMode="single" dataKey="id" @rowSelect="onRowSelect">
      <Column field="triage.code" :header="$t('triage')" :sortable="true" class="text-center">
        <template #body="slotProps">
          <TriageBadgeStatus :triage="slotProps.data.triage" size="mini"/>
        </template>
      </Column>
      <Column field="fullname" :header="$t('name')" :sortable="true"></Column>
      <Column field="note" :header="$t('note')" :sortable="true"></Column>
      <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
      <Column field="date" :header="$t('ordered')" :sortable="true" class="text-center">
        <template #body="slotProps">
          <small>{{timeDiff(slotProps.data.date)}}</small>
        </template>
      </Column>
      <Column field="status" headerStyle="width: 2em;" header="" :sortable="true" class="text-center">
        <template #header>
          <i class="pi pi-check"></i>
        </template>
        <template #body="slotProps">
          <Button :label="$t('process')" class="p-button-sm p-button-secondary" @click="markSent(slotProps.data)"/>
        </template>
      </Column>
      <template #empty>
        <div class="text-center">{{$t('no_record_found')}}</div>
      </template>
    </DataTable>
  </div>
  <div class="page-loading" v-else>
    <ProgressSpinner />
  </div>


  <Dialog :header="$t('transfer_patient')" v-model:visible="sent_dialog" class="medium-dialog">
    <PatientTransferEdit :pid="sent_dialog" :request="sent_dialog_request" @change="transferChanged" />
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

import PatientTransferEdit from '@/components/PatientTransferEdit';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import patient from '@/models/patient';
import { RequestTransferService } from '@/services/request_transfer';
import timeDiff from '@/helpers/time_diff';

export default {
  components: {
    Button,InputText,DataTable,Column,ProgressSpinner,Dialog,PatientTransferEdit,TriageBadgeStatus
  },
  data() {
    return {
      isReady: false,
      response: [],
      sent_dialog: null,
      sent_dialog_request: null
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      RequestTransferService.pending()
        .then((res)=>{
          if(res) {
            this.response = res
            this.isReady = true
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    markSent(selected) {
      //console.log(selected)
      this.sent_dialog = selected.patient_id
      this.sent_dialog_request = selected
    },
    transferChanged() {
      this.loadList()
      this.sent_dialog = null
      this.sent_dialog_request = null
    },
    timeDiff: timeDiff
  },
  computed : {
    list() {
      if(!this.response || !this.response.list)return;
      let list = []
      this.response.list.map((item)=>{
        if(this.filter_keyword) {
          let searchvals = [item.hn,item.fullname]
          searchvals = searchvals.join()
          if (searchvals.toLowerCase().indexOf(this.filter_keyword) < 0) {
            return
          }
        }
        list.push({
          "id": item.id,
          "patient_id": item.patient.id,
          "date": item.ordered_time,
          "fullname": item.patient.fullname,
          "address": item.patient.address,
          "type": item.type,
          "triage": item.patient.triage,
          "phone": item.patient.mobile,
          "note": item.note
        });
      })
      
      return list
    },
  }
}
</script>

<style lang="scss">
</style>