<template>
  <div class="content-container">
    <div class="grid">
      <div class="col"><h1>{{$t('waiting_list')}}</h1></div>
      <div class="col text-right"><Button @click="newPatient" :label="$t('new_waiting_patient')" class="p-button-lg" icon="pi pi-plus" iconPos="right"/></div>
    </div>
    <template v-if="isReady">
      <div class="grid"> 
        <div class="col">
          <Dropdown v-model="patient_filters['triage_code'].value" :options="triages" optionValue="code" optionLabel="text" placeholder="Triages" style="min-width:150px">
            <template #option="slotProps">
              <i class="triage-dot" :style="'background:'+slotProps.option.color"></i>&nbsp;&nbsp;<span>{{slotProps.option.text}}</span>
            </template>
          </Dropdown>
        </div>
        <div class="col-1">
          <Button icon="pi pi-times" @click="clearPatientFilters" label="Clear" class="p-button-text" style="width:100%" />
        </div>
        <div class="col-3">
          <div class="p-inputgroup">
              <InputText placeholder="Search HN, Name, Phone" v-model="patient_filters.global.value"/>
              <Button icon="pi pi-search" />
          </div>
        </div>
      </div>

      <DataTable :value="patients" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" 
        :paginator="true" :rows="20" selectionMode="single" dataKey="hn" 
        v-model:filters="patient_filters" :globalFilterFields="['name','hn','phone']">
        <Column headerStyle="width: 3em;" bodyStyle="padding:0;line-height:0;" field="image">
          <template #body="slotProps">
            <img :src="slotProps.data.image" class="product-image" width="60" />
          </template>
        </Column>
        <Column field="triage_code" :header="$t('color')" class="text-center">
          <template #body="slotProps">
            <TriageBadgeStatus size="mini" :triage="slotProps.data.triage" />
          </template>
        </Column>
        <Column field="id" :header="$t('id')" :sortable="true" class="text-center"></Column>
        <Column field="hn" :header="$t('hn')" :sortable="true" class="text-center"></Column>
        <Column field="name" :header="$t('name')" :sortable="true"></Column>
        <Column field="age" :header="$t('age')" :sortable="true" class="text-center"></Column>
        <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
        <Column field="spo2.value" :header="$t('spo2')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.spo2"/>
          </template>
        </Column>
        <Column field="temp.value" :header="$t('temp')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.temp"/>
          </template>
        </Column>
        <Column field="note" :header="$t('note')" :sortable="true"></Column>
        <Column headerStyle="width: 2em;" :header="$t('view')" :sortable="false" class="text-center">
          <template #body="slotProps">
            <Button :label="$t('view')" class="p-button-sm p-button-secondary" @click="doView(slotProps.data.id)"/>
          </template>
        </Column>
        <Column headerStyle="width: 2em;" :header="$t('approve')" :sortable="false" class="text-center">
          <template #body="slotProps">
            <Button :label="$t('process')" class="p-button-sm p-button-primary" @click="doRegister(slotProps.data.id)"/>
          </template>
        </Column>
        <template #empty>
          <div class="text-center">{{$t('no_record_found')}}</div>
        </template>
      </DataTable>
    </template>
    <div class="page-loading" v-else>
      <ProgressSpinner />
    </div>
  </div>

  <Dialog :header="$t('view_patient')" :modal="true" v-model:visible="dialog_patient" class="large-dialog">
    <PatientProfile v-if="dialog_patient" :id="dialog_patient" @close="dialog_patient=null" />
  </Dialog>

  <Dialog :modal="true" header="Patient" v-model:visible="patient_edit" class="large-dialog" @hide="patientEditChanged">
    <PatientForm :id="patient_edit" action="approve" @back="patientEditChanged"/>
  </Dialog>

  <Dialog :modal="true" header="Patient" v-model:visible="patient_add" class="large-dialog">
    <PatientForm action="add_waiting" @back="patientAddChanged"/>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import PatientProfile from '@/components/PatientProfile';
import PatientForm  from '@/components/PatientForm';

import InterpretationText from '@/components/InterpretationText';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import { FilterMatchMode } from 'primevue/api';
import patient from '@/models/patient';
import { EtcService } from '@/services/etc';
export default {
  components: {
    Button,Dropdown,InputText,DataTable,Column,InterpretationText,ProgressSpinner,TriageBadgeStatus,PatientProfile,Dialog,PatientForm
  },
  data() {
    return {
      isReady: false,
      response: null,
      filter_ward: null,
      filter_keyword: null,
      filter_triage: null,
      patient_filters: {},
      dialog_patient: null,
      patient_edit: null,
      wards: [],
      triages: [],
      patients: [],
      patient_add: false
    }
  },
  mounted() {
    this.loadData()
    this.initPatientFilters()
  },
  methods: {
    loadData() {
      EtcService.waitinglist()
        .then((res)=>{
          //console.log(res)
          if(res) {
            this.response = res
            this.patients = this.initPatients();
            this.isReady = true
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })

      EtcService.triages()
        .then((res)=>{
          if(res) {
            this.triages = res.triage
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    patientAddChanged() {
      this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:this.$t('new_patient_added'), life: 3000});
      this.loadData()
      this.patient_add = false
    },
    initPatients() {
      if(!this.response || !this.response.list)return;
      let patients = []
      this.response.list.map((item)=>{
        patients.push({
          "id": item.id,
          "image": item.profile_pic,
          "ward_id": item.ward_id,
          "ward": item.ward,
          "bed": item.bed,
          "gender": item.gender,
          "hn": item.hn,
          "phone": item.mobile,
          "name": item.fullname,
          "age": item.age,
          "spo2": item.vital_signs.spo2,
          "pulse": item.vital_signs.pulse,
          "bp": item.vital_signs.bp,
          "temp": item.vital_signs.temp,
          "note": '',
          "important": false,
          "star": false,
          "triage": item.triage,
          "triage_code": item.triage.code
        });
      })
      return patients
    },
    initPatientFilters() {
      this.patient_filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'triage_code': {value: null, matchMode: FilterMatchMode.EQUALS}
      }
    },
    clearPatientFilters() {
      this.initPatientFilters()
    },
    doRegister(id) {
      //this.$router.push('/main/patient/'+id+'/approve')
      this.patient_edit = id
    },
    newPatient() {
      this.patient_add = true
    },
    doView(id) {
      //console.log(id)
      this.dialog_patient = id
    },
    patientEditClose() {
      this.patient_edit = null
    },
    patientEditChanged() {
      this.patient_edit = null
      this.loadData()
    },
    processData(data) {
      if( data && Array.isArray(data) ) {
        return {
          labels: data.map((item)=>{return item.date}),
          datasets: [
            {
              data: data.map((item)=>{return item.value}),
              ...this.datasetOption
            }
          ]
        }
      } else {
        return null
      }
    },
    processToday(data) {
      let last
      if( data && Array.isArray(data) ) {
        last = data[data.length - 1]
        if(last) return last.value
        else return null
      } else {
        return null
      }
    }
  },
  computed: {
    triageChartData() {
      if(!this.response.triage) return null
      return {
        labels: ['green','yellow','red'],
        datasets: [
          {
            data: [this.response.triage.green,this.response.triage.yellow,this.response.triage.red],
            backgroundColor: ["#00B151","#FAA61F","#FB5533"],
          }
        ]
      }
    },
    admissionData() {
      return this.processData(this.response.admission_by_date)
    },
    admissionToday() {
      return this.processToday(this.response.admission_by_date)
    },
    dischargeData() {
      return this.processData(this.response.discharge_by_date)
    },
    dischargeToday() {
      return this.processToday(this.response.discharge_by_date)
    },
    transferData() {
      return this.processData(this.response.transfer_by_date)
    },
    transferToday() {
      return this.processToday(this.response.transfer_by_date)
    }
  }
}
</script>