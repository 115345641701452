export default function timeDiff(datetime) {
  let dt = Date.parse(datetime)
  let timeStamp = new Date(dt)
  let now = new Date()

  let secondsPast = (now.getTime() - timeStamp) / 1000;
  if (secondsPast < 60) {
    return parseInt(secondsPast) + ' sec ago';
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + ' min ago';
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + ' hrs ago';
  }
  if (secondsPast > 86400) {
    const day = timeStamp.getDate()
    const month = timeStamp.getMonth()+1
    const year = timeStamp.getFullYear()
    return day + "/" + month +'/'+ year;
  }
  /*
  const current = new Date()
  const previous = new Date(previous)
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed/1000) + ' seconds ago';   
  }

  else if (elapsed < msPerHour) {
    return Math.round(elapsed/msPerMinute) + ' minutes ago';   
  }
  else if (elapsed < msPerDay ) {
    return Math.round(elapsed/msPerHour ) + ' hours ago';   
  }
  else if (elapsed < msPerMonth) {
    return Math.round(elapsed/msPerDay) + ' days ago';   
  }
  else if (elapsed < msPerYear) {
    return Math.round(elapsed/msPerMonth) + ' months ago';   
  }
  else {
    return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
  }
  */
}