import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const EtcService = {
  dashboard,
  pacman_spo2,
  waitinglist,
  wards,
  dischargelist,
  triages
};

function dashboard () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}dashboard`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function waitinglist () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/waiting-list`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function dischargelist () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/discharge-list`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function pacman_spo2 (data) {
  let header = {...{"Content-Type": "multipart/form-data"},...authHeader()}
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}upload/spo2`,
      method: 'POST',
      headers: header,
      data: data
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function wards () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}ward`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function triages () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}ward`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}