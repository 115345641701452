import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Main from '../views/main/Main.vue'
import Register from '../views/main/Register.vue'
import Patients from '../views/main/Patients.vue'
import Dashboard from '../views/main/Dashboard.vue'
import Patient from '../views/main/Patient.vue'
import PatientEdit from '../views/main/PatientEdit.vue'
import PatientApprove from '../views/main/PatientApprove.vue'
import ProgressNotes from '../views/main/ProgressNotes.vue'
import WaitingList from '../views/main/WaitingList.vue'
import Fulfillments from '../views/main/Fulfillments.vue'
import RequestTransfer from '../views/main/RequestTransfer.vue'
import DischargeList from '../views/main/DischargeList.vue'

import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/main', component: Main,
    children: [
      { path: '/main/waiting_list', component: WaitingList },
      { path: '/main/dashboard', component: Dashboard },
      { path: '/main/register/:id?', component: Register },
      { path: '/main/patients', component: Patients },
      { path: '/main/patient/:id', component: Patient },
      { path: '/main/patient/:id/edit', component: PatientEdit },
      { path: '/main/patient/:id/approve', component: PatientApprove },
      { path: '/main/progress', component: ProgressNotes },
      { path: '/main/fulfillments', component: Fulfillments },
      { path: '/main/request_transfer', component: RequestTransfer },
      { path: '/main/discharge_list', component: DischargeList }
      // { path: '/daily/medicine', component: Medicine }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['user/loggedIn']
  window.scrollTo(0, 0)
  if (to.name == 'Login' && isLoggedIn) {
    next({ path: '/home' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
