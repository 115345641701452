<template>
  <app-header :nav="true" />
  <app-menu />
  <div class="layout-content" :class="{sidebarActive:sidebarActive}">
    <router-view></router-view>
  </div>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppMenu from './Menu.vue';
export default {
  name: 'Main',
  components: {
    AppHeader,AppMenu
  },
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  computed: {
    sidebarActive() {
      return this.$store.state.app.sidebarActive
    }
  }
}
</script>
