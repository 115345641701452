<template>
  <div class="content-container">
    <div class="grid">
      <div class="col"><h1>{{$t('discharge_list')}}</h1></div>
    </div>
    <template v-if="isReady">
      <div class="grid">
        <div class="col-3">
          <div class="p-inputgroup">
              <InputText placeholder="Search HN, Name, Phone" v-model="patient_filters.global.value"/>
              <Button icon="pi pi-search" />
          </div>
        </div>
        <div class="col-1">
          <Button icon="pi pi-times" @click="clearPatientFilters" label="Clear" class="p-button-text" style="width:100%" />
        </div>
      </div>

      <DataTable :value="patients" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" 
        :paginator="true" :rows="20" selectionMode="single" dataKey="hn"  sortField="discharged_at" :sortOrder="-1"
        v-model:filters="patient_filters" :globalFilterFields="['name','hn','phone']">
        <Column headerStyle="width: 3em;" bodyStyle="padding:0;line-height:0;" field="image">
          <template #body="slotProps">
            <img :src="slotProps.data.image" class="product-image" width="60" />
          </template>
        </Column>
        <Column field="triage.code" :header="$t('color')" class="text-center">
          <template #body="slotProps">
            <TriageBadgeStatus size="mini" :triage="slotProps.data.triage" />
          </template>
        </Column>
        <Column field="id" :header="$t('id')" :sortable="true" class="text-center"></Column>
        <Column field="hn" :header="$t('hn')" :sortable="true" class="text-center"></Column>
        <Column field="name" :header="$t('name')" :sortable="true"></Column>
        <Column field="age" :header="$t('age')" :sortable="true" class="text-center"></Column>
        <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
        <Column field="spo2.value" :header="$t('spo2')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.spo2"/>
          </template>
        </Column>
        <Column field="temp.value" :header="$t('temp')" :sortable="true" class="text-center">
          <template #body="slotProps">
            <InterpretationText :interpretation="slotProps.data.temp"/>
          </template>
        </Column>
        <Column field="note" :header="$t('note')" :sortable="true"></Column>
        <Column field="discharged_at" :header="$t('discharged')" :sortable="true">
          <template #body="slotProps">
          {{timeDiff(slotProps.data.discharged_at)}}
          </template>
        </Column>
        <Column headerStyle="width: 2em;" :header="$t('view')" :sortable="false" class="text-center">
          <template #body="slotProps">
            <Button :label="$t('view')" class="p-button-sm p-button-secondary" @click="doView(slotProps.data.id)"/>
          </template>
        </Column>
        <template #empty>
          <div class="text-center">{{$t('no_record_found')}}</div>
        </template>
      </DataTable>
    </template>
    <div class="page-loading" v-else>
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import PatientProfile from '@/components/PatientProfile';

import InterpretationText from '@/components/InterpretationText';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import { FilterMatchMode } from 'primevue/api';
import patient from '@/models/patient';
import { EtcService } from '@/services/etc';
import timeDiff from '@/helpers/time_diff';

export default {
  components: {
    Button,InputText,DataTable,Column,InterpretationText,ProgressSpinner,TriageBadgeStatus
  },
  data() {
    return {
      isReady: false,
      response: null,
      filter_ward: null,
      filter_keyword: null,
      filter_triage: null,
      patient_filters: {},
      dialog_patient: null,
      patient_edit: null,
      wards: [],
      patients: [],
      patient_add: false
    }
  },
  mounted() {
    this.loadData()
    this.initPatientFilters()
  },
  methods: {
    timeDiff: timeDiff,
    loadData() {
      EtcService.dischargelist()
        .then((res)=>{
          console.log(res)
          if(res) {
            this.response = res
            this.patients = this.initPatients();
            this.isReady = true
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    initPatients() {
      if(!this.response || !this.response.patients)return;
      let patients = []
      this.response.patients.map((item)=>{
        patients.push({
          "id": item.id,
          "image": item.profile_pic,
          "ward_id": item.ward_id,
          "ward": item.ward,
          "bed": item.bed,
          "gender": item.gender,
          "hn": item.hn,
          "phone": item.mobile,
          "name": item.fullname,
          "age": item.age,
          "spo2": item.vital_signs.spo2,
          "pulse": item.vital_signs.pulse,
          "bp": item.vital_signs.bp,
          "temp": item.vital_signs.temp,
          "note": '',
          "important": false,
          "star": false,
          "triage": item.triage,
          "discharged_at": item.discharged_at,
        });
      })
      return patients
    },
    initPatientFilters() {
      this.patient_filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      }
    },
    clearPatientFilters() {
      this.initPatientFilters()
    },
    doView(id) {
      //console.log(id)
      //this.dialog_patient = id
      this.$router.push('/main/patient/'+id)
    },
  },
  computed: {
  }
}
</script>