<template>
  <div class="content-container">
    <h3>{{$t('dashboard')}}</h3>
    <template v-if="isReady">
      <div class="grid">
        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-info">
                <i class="pi pi-users"></i>
                <span>{{$t('total_patient')}}</span>
                <strong>{{response.total}}</strong>
              </div>        
            </template>
          </Card>
        </div>

        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-info">
                <i class="pi pi-users"></i>
                <span>{{$t('current_admit')}}</span>
                <strong>{{response.total_admit}}</strong>
              </div>
            </template>
          </Card>
        </div>

        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-info">
                <i class="pi pi-clock"></i>
                <span>{{$t('waiting_patient')}}</span>
                <strong>{{response.total_waiting}}</strong>
              </div>        
            </template>
          </Card>
        </div>
        
        <div class="col-12 sm:col-6 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('admission_count')}} <small>{{$t('today')}}</small>:<strong>{{admissionToday}}</strong></span>
                <div style="height:150px">
                  <Chart v-if="admissionData" type="line" :data="admissionData" :options="lineoption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>

        <div class="col-12 sm:col-6 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('patient_triage_count')}}</span>
                <div style="height:150px">
                  <Chart type="pie" :data="triageChartData" :options="pieChartOption" />
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('progress_note_count')}}</span>
                <div style="height:150px">
                  <Chart v-if="progressData" type="line" :data="progressData" :options="lineoption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>

        <div class="col-12">
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('patient_ward_count')}}</span>
                <div>
                  <Chart :height="200" v-if="wardData" type="bar" :data="wardData" :options="baroption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>
      </div>
      <br/>
      <h3>{{$t('total_cases')}}</h3>
      <div class="grid">
        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('cumulative_case_count')}}</span>
                <div style="height:150px">
                  <Chart v-if="cumulativeData" type="line" :data="cumulativeData" :options="linefilloption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>
        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('patient_age_range')}}</span>
                <div style="height:150px">
                  <Chart v-if="ageData" type="bar" :data="ageData" :options="baroption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>
        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('patient_gender_count')}}</span>
                <div style="height:150px">
                  <Chart type="pie" :data="genderData" :options="pieChartOption" />
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <br/>
      <h3>{{$t('discharge_statistic')}}</h3>
      <div class="grid">
        <div class="col-12 md:col-4"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('discharge_disposition')}}</span>
                <div style="height:150px">
                  <Chart type="pie" :data="dischargeDispoData" :options="pieChartOption" />
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12 md:col-8"> 
          <Card>
            <template #content>
              <div class="dashboard-card-chart">
                <span>{{$t('discharge_count')}}</span>
                <div style="height:150px">
                  <Chart v-if="dischargeData" type="line" :data="dischargeData" :options="lineoption" />
                </div>
              </div>        
            </template>
          </Card>
        </div>
      </div>
    </template>
    <div class="page-loading" v-else>
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import ProgressSpinner from 'primevue/progressspinner';

import { FilterMatchMode } from 'primevue/api';
import patient from '@/models/patient';
import { EtcService } from '@/services/etc';
import timeDiff from '@/helpers/time_diff';

export default {
  components: {
    Card,Chart,ProgressSpinner
  },
  data() {
    return {
      isReady: false,
      response: null,
      pieChartOption: {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        aspectRatio: 2,
        elements: {
          arc: {
            borderWidth: 1
          }
        },
        plugins:{
          legend:{
            position: 'right',
            labels: {
              padding: 5,
              boxWidth: 10,
              boxHeight: 10,
              pointStyle: 'circle',
              usePointStyle: true,
            },
          },
          tooltip: {
            mode: 'index'
          }
        },
      },
      datasetOption: {
        fill: false,
        borderColor: '#42A5F5',
        pointBorderWidth: 0,
        lineTension: 0,
      },
      linedata: {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
          {
            label: 'First Dataset',
            data: [65, 59, 67, 81, 76],
            fill: false,
            borderColor: '#42A5F5',
            pointBorderWidth: 0,
            lineTension: 0,
          }
        ]
      },
      lineoption: {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
          legend:{
            display: false
          }
        },
        layout: {
          padding: 5
        },
        elements: {
          point:{
            radius: 3
          }
        },
        scales: {
          y: {
            display: true,
          },
          x: {
            display: false,
          }
        }
      },
      linefilloption: {
        responsive: true,
        maintainAspectRatio: false,
        fill:true,
        plugins:{
          legend:{
            display: false
          }
        },
        layout: {
          padding: 5
        },
        elements: {
          point:{
            radius: 3
          }
        },
        scales: {
          y: {
            display: true,
          },
          x: {
            display: false,
          }
        }
      },
      baroption: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 5
        },
        plugins:{
          legend:{
            display: false
          }
        },
        elements: {
          point:{
            radius: 3
          }
        },
      }
    }
  },
  mounted() {
    EtcService.dashboard()
      .then((res)=>{
        if(res) {
          this.response = res
          this.isReady = true
        } else {
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
        }
      },(res)=>{
        this.errors = res
      })
  },
  methods: {
    processData(data) {
      if( data && Array.isArray(data) ) {
        return {
          labels: data.map((item)=>{return item.date}),
          datasets: [
            {
              data: data.map((item)=>{return item.value}),
              ...this.datasetOption
            }
          ]
        }
      } else {
        return null
      }
    },
    processToday(data) {
      let last
      if( data && Array.isArray(data) ) {
        last = data[data.length - 1]
        if(last) return last.value
        else return null
      } else {
        return null
      }
    }
  },
  computed: {
    triageChartData() {
      if(!this.response.triage_current)return {}
      let data = Object.values(this.response.triage_current)
      return {
        labels: data.map((item)=>{return item.name?item.name:'N/A'}),
        datasets: [
          {
            data: data.map((item)=>{return item.value}),
            backgroundColor: data.map((item)=>{return item.color}),
          }
        ]
      }
    },
    genderData() {
      if(!this.response.sex)return {}
      let data = Object.values(this.response.sex)
      return {
        labels: data.map((item)=>{return item.label?item.label:'N/A'}),
        datasets: [
          {
            data: data.map((item)=>{return item.value}),
            backgroundColor: ['rgb(255, 205, 86)','rgb(255, 99, 132)','rgb(54, 162, 235)'],
          }
        ]
      }
    },
    dischargeDispoData() {
      if(!this.response.discharge_disposition)return {}
      let data = Object.values(this.response.discharge_disposition)
      return {
        labels: data.map((item)=>{return item.label?item.label:'N/A'}),
        datasets: [
          {
            data: data.map((item)=>{return item.value}),
            backgroundColor: ['rgb(54, 162, 235)','rgb(255, 205, 86)','rgb(255, 99, 132)'],
          }
        ]
      }
    },
    wardData() {
      let data = this.response.wards
      if(!data) return null;
      return {
          labels: data.map((item)=>{return item.title}),
          datasets: [
            {
              label: this.$t("patient_count"),
              data: data.map((item)=>{return item.count_current}),
              backgroundColor: '#42A5F5'
            }
          ]
        }
    },
    ageData() {
      let data = this.response.age
      if(!data) return null;
      return {
          labels: data.map((item)=>{return item.label}),
          datasets: [
            {
              label: this.$t("patient_count"),
              data: data.map((item)=>{return item.value}),
              backgroundColor: '#42A5F5'
            }
          ]
        }
    },
    
    progressData() {
      return this.processData(this.response.progress_note_by_date)
    },
    admissionData() {
      return this.processData(this.response.admission_by_date)
    },
    admissionToday() {
      return this.processToday(this.response.admission_by_date)
    },
    cumulativeData() {
      if(!this.response.cumulative_by_date)return {}
      let data = Object.values(this.response.cumulative_by_date)
      return {
        labels: data.map((item)=>{return item.date}),
        datasets: [
          {
            data: data.map((item)=>{return item.value}),
            ...{...this.datasetOption,...{fill:true,backgroundColor: 'rgba(66, 164, 245,0.2)'}}
          }
        ]
      }
    },
    dischargeData() {
      return this.processData(this.response.discharge_by_date)
    },
    dischargeToday() {
      return this.processToday(this.response.discharge_by_date)
    },
    transferData() {
      return this.processData(this.response.transfer_by_date)
    }
  }
}
</script>

<style lang="scss">
.dashboard-card-chart {

  > span {
    display:block;
    text-align: center; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
}
.dashboard-card-info {
  display: flex;
  line-height: 1.5em;
  i {
    display: block;
    color: var(--primary-color);
    font-size: 1.5em;
  }
  span {
    padding-left: 10px;
    flex: 1;
  }
  strong {
    color: var(--secondary-color);
    font-size: 1.5em;

  }
}
</style>