let theme = localStorage.getItem('theme')
if(!theme)theme='light'
let locale = localStorage.getItem('locale')
if(!locale)locale='th'
const state = {
  theme: theme,
  locale: locale,
  sidebarActive: false,
  loading: null,
  ward: null
}

const actions = {
  setTheme(context, ans) {
    context.commit('theme',ans)
    localStorage.setItem('theme', ans)
  },
  setWard(context, ward) {
    context.commit('ward',ward)
  },
  toggleTheme(context) {
    if (state.theme=='light') {
      state.theme='dark'
    } else {
      state.theme='light'
    }  
    context.commit('theme',state.theme)
    localStorage.setItem('theme', state.theme)
  },
  setLocale(context, ans) {
    context.commit('locale',ans)
    localStorage.setItem('locale', ans)
  },
  setSidebarActive(context, ans) {
    context.commit('sidebar',ans)
  },
  toggleSidebarActive(context) {
    context.commit('toggleSidebar')
  },
  showLoading(context,msg) {
    context.commit('doShowLoading',msg)
  },
  hideLoading(context) {
    context.commit('doHideLoading')
  },
}

const mutations = {
  theme(state,ans) {
    state.theme = ans
  },
  ward(state,ans) {
    state.ward = ans
  },
  locale(state,ans) {
    state.locale = ans
  },
  sidebar(state,ans) {
    state.sidebarActive = ans
  },
  toggleSidebar(state) {
    state.sidebarActive = !state.sidebarActive
  },
  doShowLoading(state,msg) {
    if(!msg)msg = 'Loading...'
    state.loading = msg
  },
  doHideLoading(state) {
    state.loading = null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}