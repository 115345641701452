<template>
  <div class="content-container" v-if="isReady">
    <Button class="p-button-text" @click="$router.go(-1)">&larr; {{$t('go_back')}}</Button>
    <PatientCards v-if="result && result.patient" :data="result" @change="patientChanged" />
    
    <Card>
      <template #content>
        <div class="grid">
          <div class="col">
            <h4>{{$t('progress_note')}}</h4>
          </div>
          <div class="col-12 sm:col-6 text-right">
            <Button :label="$t('add_progress_note')" icon="pi pi-plus" iconPos="left" class="p-button-outlined"  @click="progressNote(null)" v-if="result && result.patient && result.patient.status==='admit'" />
          </div>
        </div>
      
        <DataTable v-if="progress_notes" :value="progress_notes" responsiveLayout="scroll"
        showGridlines stripedRows class="p-datatable-sm" :paginator="true" :rows="5" dataKey="id"
        sortField="issued_at" :sortOrder="-1" >
          <Column field="issued_at" :header="$t('date_time')" :sortable="true" class="text-center">
            <template #body="slotProps">
              {{slotProps.data.issued_time}}
            </template>
          </Column>
          <Column field="spo2.value" :header="$t('spo2')" :sortable="true" class="text-center">
            <template #body="slotProps"><InterpretationText :interpretation="slotProps.data.spo2"/></template>
          </Column>
          <Column field="temp.value" :header="$t('temp')" :sortable="true" class="text-center">
            <template #body="slotProps"><InterpretationText :interpretation="slotProps.data.temp"/></template>
          </Column>
          <Column field="rr.value" :header="$t('rr')" :sortable="true" class="text-center">
            <template #body="slotProps"><InterpretationText :interpretation="slotProps.data.rr"/></template>
          </Column>
          <Column field="bp.value" :header="$t('bp')" :sortable="true" class="text-center">
            <template #body="slotProps"><InterpretationText :interpretation="slotProps.data.bp"/></template>
          </Column>
          <Column field="pulse.value" :header="$t('pulse')" :sortable="true" class="text-center">
            <template #body="slotProps"><InterpretationText :interpretation="slotProps.data.pulse"/></template>
          </Column>
          <Column field="symptoms" :header="$t('symptoms')" :sortable="true"></Column>
          <Column field="triage.code" :header="$t('triage')" :sortable="true" class="text-center">
            <template #body="slotProps">
              <template v-if="slotProps.data.triage">
                <triage-badge-status :triage="slotProps.data.triage"/><br/>
                <small @click="progressNoteTriage(slotProps.data.id,slotProps.data.triage)">{{$t('edit')}}</small>
              </template>
            </template>
          </Column>
          <Column field="status" :header="$t('round')" :sortable="true" class="text-center">
            <template #body="slotProps">
              <template v-if="slotProps.data.status==='confirmed'">
                <Button :label="$t('edit')" class="p-button-sm p-button-text" @click="progressNote(slotProps.data.id)"/>
              </template>
              <template v-else>
                <Button v-if="user.role==='doctor' || user.role==='nurse'" :label="$t('click_to_round')" class="p-button-sm p-button-secondary" @click="progressNote(slotProps.data.id)"/>
                <Button v-else :label="$t('edit')" class="p-button-sm p-button-secondary" @click="progressNote(slotProps.data.id)"/>
              </template>
              <template v-if="slotProps.data.recorder">
                <br/>
                <small>{{slotProps.data.recorder}}</small>
              </template>
            </template>
          </Column>
          <template #empty>
            <div class="text-center">{{$t('no_record_found')}}</div>
          </template>
        </DataTable>

        <div  v-if="chartdata" class="patient-chart">
          <br/>
          <h4>{{$t('chart')}}</h4>
          <Chart type="line" :data="chartdata" :options="chartoptions" height="100vh" />
        </div>
      </template>
    </Card>

    <Button class="p-button-text" @click="$router.go(-1)">&larr; {{$t('go_back')}}</Button>
  </div>
  <Dialog :modal="true" header="Progress Note" v-model:visible="patient_display" class="large-dialog" @hide="progressNoteClose">
    <ProgressNote :patientid="patient_display_id" :progressnoteid="patient_display_progress_note_id" @change="progressNoteChanged" @hide="progressNoteClose" role="doctor"/>
  </Dialog>

  <Dialog :modal="true" header="Change Triage" v-model:visible="patient_display_triage" class="medium-dialog" @hide="progressNoteTriageClose">
    <ProgressNoteTriage :triage="patient_display_patient_triage" :progressnoteid="patient_display_progress_note_id" @change="progressNoteTriageChanged" @hide="progressNoteTriageClose"/>
  </Dialog>
</template>

<script>
import patient from '@/models/patient';
import Card from 'primevue/card';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chart from 'primevue/chart';
import Dialog from 'primevue/dialog';

import  PatientCards  from '@/components/PatientCards';
import  InterpretationText  from '@/components/InterpretationText';
import { PatientService } from '@/services/patient';
import ProgressNote from '@/components/ProgressNote';
import ProgressNoteTriage from '@/components/ProgressNoteTriage';
import TriageBadgeStatus from '@/components/TriageBadgeStatus';
export default {
  components: {
    Card,Button,DataTable,Column,Chart,PatientCards,InterpretationText,Dialog,ProgressNote,TriageBadgeStatus,ProgressNoteTriage
  },
  data() {
    return {
      chartoptions: {
        responsive: true,
        stacked: false,
        plugins:{
          legend:{
            labels: {
              boxWidth: 20,
              boxHeight: 20,
              pointStyle: 'circle',
              usePointStyle: true
            }
          },
          tooltip: {
            mode: 'index'
          }
        },
        scales: {
          'y-axis-spo2': {
            type: 'linear',
            display: true,
            position: 'left',
            min: 70,
            max: 100,
            ticks: {
              color: '#00B632'
            },
            grid: {
              display: true
            }
          },
          'y-axis-temp': {
            type: 'linear',
            display: true,
            position: 'left',
            suggestedMin: 20,
            suggestedMax: 50,
            ticks: {
              color: '#42A5F5',
            },
            grid: {
              display: true
            }
          }, 
          'y-axis-bp':{
            type: 'linear',
            display: true,
            position: 'left',
            min: 50,
            max: 200,
            ticks: {
              color: '#FCB944'
            },
            grid: {
              display: true
            }
          }, 
          'y-axis-rr':{
            type: 'linear',
            display: true,
            position: 'right',
            min: 0,
            max: 200,
            ticks: {
              color: '#8F1FBA'
            },
            grid: {
              display: true
            }
          },
          'y-axis-pulse':{
            type: 'linear',
            display: true,
            position: 'right',
            min: 50,
            max: 200,
            ticks: {
              color: '#C0180E'
            },
            grid: {
              display: true
            }
          }
        }
      },
      isReady: false,
      result: null,
      patient: false,
      patient_display_progress_note_id:null,
      patient_display_id: null,
      patient_display: false,
      patient_display_triage: false,
      patient_display_patient_triage: null,
      chartdata: null,
      user: {}
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      PatientService.patient(this.$route.params.id)
        .then((res)=>{
          this.result = res
          this.isReady = true
          this.user = res.user

          this.chartdata = this.chartMap()
        },(res)=>{
          this.errors = res
          this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
        })
    },
    patientChanged() {
      this.loadList()
    },
    chartMap() {
      if(!this.result.chart)return null
      let chartLabels = []
      let chartSpo2 = []
      let chartTemp = []
      let chartBp = []
      let chartRr = []
      let chartPulse = []
      this.result.chart.map((item)=>{
        chartLabels.push(item.datetime)
        chartSpo2.push(item.spo2)
        chartTemp.push(item.temp)
        chartBp.push([item.sbp,item.dbp])
        chartRr.push(item.rr)
        chartPulse.push(item.pulse)
      });
      //console.log(chartBp)
      return {
        labels: chartLabels,
        datasets: [{
          label: 'SPO2',
          spanGaps: true,
          borderColor: '#00B632',
          pointBackgroundColor: "#00B632",
          data: chartSpo2,
          yAxisID: 'y-axis-spo2'
        }, {
          label: 'Temp',
          spanGaps: true,
          borderColor: '#00B3D6',
          pointBackgroundColor: "#00B3D6",
          data: chartTemp,
          yAxisID: 'y-axis-temp'
        }, {
          label: 'RR',
          spanGraps: true,
          pointBackgroundColor:'#8F1FBA',
          borderColor:'#8F1FBA',
          data: chartRr,
          yAxisID: 'y-axis-rr',
        },{
          label: 'Pulse',
          fill: false,
          borderColor: '#C0180E',
          pointBackgroundColor: "#C0180E",
          lineTension: 0,
          data: chartPulse,
          yAxisID: 'y-axis-pulse',
        },{
          label: 'BP',
          type: 'bar',
          fill: true,
          borderColor: "#FCB944",
          backgroundColor: "#FCB944",
          lineTension: 0,
          barThickness: 10,
          data: chartBp,
          yAxisID: 'y-axis-bp',
          tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.raw.join('/')
                }
                return label;
              }
            }
          }
        }]
      }
    },
    progressNote(id) {
      this.patient_display_progress_note_id = id
      this.patient_display_id = this.result.patient.id
      this.patient_display = true
      //this.$router.push('/main/progress/'+event.data.id)
    },
    progressNoteClose() {
      this.patient_display_progress_note_id = null
      this.patient_display_id = null
      this.patient_display = false
    },
    progressNoteChanged() {
      this.loadList()
      this.progressNoteClose()
    },
    progressNoteTriage(id,triage) {
      this.patient_display_progress_note_id = id
      this.patient_display_patient_triage = triage
      this.patient_display_triage = true
    },
    progressNoteTriageClose() {
      this.patient_display_progress_note_id = null
      this.patient_display_patient_triage = null
      this.patient_display_triage = false
    },
    progressNoteTriageChanged() {
      this.loadList()
      this.progressNoteTriageClose()
    },
  },
  computed: {
    progress_notes() {
      let result = [];
      if(this.result && this.result.progress_notes && Array.isArray(this.result.progress_notes)){
        this.result.progress_notes.map((item)=>{
          result.push({
            "id": item.id,
            "created_at":item.created_at,
            "issued_at":item.issued_at,
            "issued_time":item.issued_time,
            "spo2":item.vital_signs.spo2,
            "temp":item.vital_signs.temp,
            "rr":item.vital_signs.rr,
            "bp":item.vital_signs.bp,
            "pulse":item.vital_signs.pulse,
            "symptoms":item.symptoms,
            "triage":item.triage,
            "status":item.status,
            "recorder":item.recorder
          })
        })
        return result
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
.patient-chart {
  .p-chart {
    height: 80vh;
    max-height: 500px;
    min-height: 400px;
  }
}
</style>