<template>
  <div class="formgroup grid-col-1">
    <div class="form-item">
      <label>{{$t('move_to')}}</label>
      <div  class="form-item-choices grid-col-2">
        <div>
          <div class="field-checkbox">
            <RadioButton id="transfer_ci" name="transfer_type" value="ci" v-model="transfer_type" />
            <label for="transfer_ci">{{$t('move_to_ci')}}</label>
          </div>
        </div>
        <div>
          <div class="field-checkbox">
            <RadioButton id="transfer_refer" name="transfer_status" value="refer" v-model="transfer_type" />
            <label for="transfer_refer">{{$t('refer_to_hispital')}}</label>
          </div>
        </div>
        <div>
          <div class="field-checkbox">
            <RadioButton id="transfer_bed" name="transfer_status" value="bed" v-model="transfer_type" />
            <label for="transfer_bed">{{$t('ward_bed')}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-item">
      <label>{{$t('status')}}</label>
      <div  class="form-item-choices">
        <div class="field-checkbox">
          <RadioButton id="transferred" name="transfer_status" value="transferred" v-model="transfer_status" />
          <label for="transferred">{{$t('transferred')}}</label>
        </div>
        <div class="field-checkbox">
          <RadioButton id="request_for_transfer" name="transfer_status" value="request_for_transfer" v-model="transfer_status" />
          <label for="request_for_transfer">{{$t('request_for_transfer')}}</label>
        </div>
      </div>
    </div>
    <div class="form-item" v-if="isTransferHosp">
      <label>{{$t('transfer_hospital')}}</label>
      <div><InputText v-model="transfer_destination" type="text" /></div>
    </div>
  </div>

  <div class="formgroup grid-col-2" v-if="isTransferBed">
    <div class="form-item">
      <label>{{$t('ward')}}</label>
      <Dropdown v-model="transfer_to_ward" :options="wards" optionValue="id" optionLabel="name" :filter="true" filterPlaceholder="Find Ward" @change="chageWard" />
    </div>
      
    <div class="form-item">
      <label>{{$t('bed')}}</label>
      <div class="form-item-box p-formgroup-inline" v-if="beds && beds.length===0" >
        {{$t('please_select_ward')}}
      </div>
      <Dropdown v-else v-model="transfer_to_bed" :options="beds" optionValue="id" optionLabel="number" :filter="true" filterPlaceholder="Find Bed"/>
    </div>
  </div>

  <div class="formgroup grid-col-1">
    <div class="form-item">
      <label>{{$t('transfer_date')}}</label>
      <div><Calendar v-model="transfer_date" :showTime="true" :hideOnDateTimeSelect="true" :showButtonBar="true" hourFormat="24" :showIcon="true" dateFormat="yy-mm-dd " /></div>
    </div>
  </div>
  
  <h5>{{$t('note')}}</h5>
  <Textarea v-model="transfer_note" :autoResize="true" rows="3" style="width:100%" />
  <p class="text-right">
    <Button type="button" icon="pi pi-reply" :label="$t('transfer_or_move')" iconPos="left" @click="doTransferPatient" />
  </p>
</template>

<script>
import { PatientService } from '@/services/patient';
import { EtcService } from '@/services/etc';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';

export default {
  components: {
    Button,InputText,RadioButton,Textarea,Dropdown,Calendar
  },
  data () {
    return {
      transfer_note: null,
      transfer_date: null,
      transfer_status: null,
      transfer_type:null,
      transfer_to_ward: null,
      transfer_to_bed: null,
      transfer_destination: null,
      wards: [],
      beds: []
    }
  },
  props: ['pid'],
  emits: ['change'],
  mounted() {
    EtcService.wards()
      .then((res)=>{
        this.loading = false
        this.wards = res.list
      },(res)=>{
        this.loading = false
        this.errors = res
      })
  },
  methods: {
    chageWard(e) {
      this.setWardBeds(e.value)
    },
    setWardBeds(ward_id) {
      if(!ward_id)return null
      this.wards.map((item)=>{
        if(item.id===ward_id) this.beds = item.beds
      })
    },
    doTransferPatient() {
      if( this.transfer_status === 'transferred' ) {
        if( this.transfer_type === 'refer' ) {
          this.sendDischarge(this.transfer_date,'refer',this.transfer_note)
        } else if( this.transfer_type === 'ci' || this.transfer_type === 'bed' ) {
          this.sendBedMove()
        }
      } else if(this.transfer_status === 'request_for_transfer') {
        let type = 'internal'
        if(this.transfer_type === 'refer') {
          type = 'external'
        }
        this.sendTransferRequest(type)
      }
      this.transfer_date = null
      this.transfer_status = null
      this.transfer_type = null
      this.transfer_to_ward = null
      this.transfer_to_bed = null
      this.transfer_destination = null
    },
    sendBedMove() {
      this.$store.dispatch('app/showLoading')
      let data = {
        bed_id: this.transfer_to_bed,
        ward_id: this.transfer_to_ward
      }
      PatientService.move(this.pid,data)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    },
    sendTransferRequest(type) {
      this.$store.dispatch('app/showLoading')
      let data = {
        type: type,
        note: this.transfer_note,
        status: "pending",
        destination: this.transfer_destination,
        transfered_at: this.transfer_date
      }
      PatientService.transfer(this.pid,data)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    },
    sendDischarge(datetime,disposition,note) {
      this.$store.dispatch('app/showLoading')
      let data = {
        datetime: datetime,
        discharge_disposition_code: disposition,
        note: note
      }
      PatientService.discharge(this.pid,data)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$router.push('/main/dashboard')
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    },
  },
  computed: {
    isTransferBed() {
      if( this.transfer_status === 'transferred' && (this.transfer_type === 'ci' || this.transfer_type === 'bed') ) {
        return true
      } else {
        return false
      }
    },
    isTransferHosp() {
      if( this.transfer_status === 'transferred' && this.transfer_type === 'refer' ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
</style>