<template>
  <div :class="'take-photo-wrapper size-'+small">
    <div v-if="url">
      <div class="preview">
        <div class="preview-image"><img :src="url"/></div>
        <Button type="button"  :label="$t('clear_image')" @click="clear" class="p-button p-button-text p-button-sm" />
      </div>
    </div>
    <div v-else-if="taking">
      <video class="camera-stream" ref="camerastream" autoplay playsinline></video>

      <div class="take-photo-buttons">
        <Button v-if="camera" type="button" icon="pi pi-times"  @click="closeCamera" class="p-button p-button-outlined p-button-sm" />
        <Button type="button" icon="pi pi-camera" :label="$t('take_photo')" @click="takePhoto" class="p-button p-button-outlined p-button-sm" />
      </div>
    </div>
    <div v-else>
      <div class="upload" @click="selectFile" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <i class="pi pi-cloud-upload"></i>
        <input type="file" name="file"  @change="onFileChange" ref="file" :accept="type_accept" class="upload-input"/>
        <p v-if="title">{{title}}</p>
        <p v-else>{{$t('drag_and_drop')}}<br/>{{$t('or_click_to_choose_file')}}</p>
      </div>
      <Button v-if="support_camera" type="button" icon="pi pi-camera" :label="$t('open_camera')" @click="openCamera" class="p-button p-button-outlined p-button-sm" />
    </div>
    <div class="loading" v-if="loading"><ProgressSpinner /></div>
    <div ref="canvaswrapper"></div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  components: {
    Button,ProgressSpinner
  },
  data () {
    return {
      files: null,
      accept: '.pdf,.jpg,.jpeg,.png',
      url: null,
      taking: false,
      loading: false,
      imageCapture: null
    }
  },
  props: ['title','camera','small','placeholder'],
  emits: ['change'],
  computed: {
    type_accept() {
      return this.accept;
    },
    support_camera() {
      return this.camera && ('ImageCapture' in window)
    }
  },
  mounted() {
    if(this.placeholder)this.url = this.placeholder
  },
  unmounted() {
    if(this.url)URL.revokeObjectURL(this.url)
  },
  methods: {
    clear() {
      this.files = null
      this.url = null
      this.taking = false
      this.imageCapture = null

      this.$emit('change', null)
    },
    openCamera() {
      this.taking = true
      this.showPhoto()
    },
    showPhoto() {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { width: 600, height: 600 },
      })
      .then(mediaStream => {
        const videoele = this.$refs.camerastream
        videoele.srcObject = mediaStream;
        //videoele.play();
        const track = mediaStream.getVideoTracks()[0];
        this.imageCapture = new ImageCapture(track);
      })
      .catch(error => {
        this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Cannot open camera', life: 3000});
        this.taking = false
      });
    },
    takePhoto() {
      this.loading = true
      this.imageCapture.grabFrame()
        .then(img => {
          //console.log(img); // ImageBitmap
          return new Promise(res => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            let ctx = canvas.getContext('bitmaprenderer');
            if(ctx) {
              ctx.transferFromImageBitmap(img);
            }
            else {
              canvas.getContext('2d').drawImage(img,0,0);
            }
            canvas.toBlob(res);
          });
        })
        .then(blob => {
          var img = document.body.appendChild(new Image())
          this.file = blob
          this.url = URL.createObjectURL(blob)
          this.emitChange()
          this.closeCamera()
          this.loading = false
        })
        .catch(error => {
          this.closeCamera()
          this.loading = false
        });
    },
    closeCamera() {
      this.$refs.camerastream.srcObject.getVideoTracks().forEach(track => track.stop());
      this.taking = false
    },
    selectFile() {
      this.$refs.file.click()
    },
    onFileChange() {
      if(this.$refs.file.files.length > 0) {
        this.url= URL.createObjectURL(this.$refs.file.files[0])
        this.file = this.$refs.file.files[0]
        this.emitChange()
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave() {
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onFileChange();
    },
    emitChange() {
      if(this.url) {
        this.$emit('change', this.file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.take-photo-wrapper {
  position: relative;
  font-size: 16px;
  &.size-small {
    font-size: 12px;
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.6);
  }

  .upload-input {
    display: none
  }
  .camera-stream {
    width: 100%;
  }

  .upload {
    margin:  0 0 0.5em;
    border: 4px dashed var(--surface-e);
    display: block;
    padding: 0.6em 0;
    color: var(--primary-color);
    font-size: 0.8em;
    text-align: center;
    display: block;
    width: 100%;
    font-weight: normal;
    cursor: pointer;
    opacity: 0.8;

    i {
      font-size: 2.6em;
    }
    p {
      margin-bottom: 0;
    }
  }
  .preview {
    text-align:center;
    .preview-image {
      border: 1px solid var(--surface-e);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .take-photo-buttons {
    display: flex;
    &>.p-button:nth-child(2){
      margin-left: 5px;
    }
  }
}
</style>