<template>
  <div class="dialog-form-content" v-if="isReady">
    <SmallPatientCard v-if="result && result.patient" :patient="result.patient"/>
    <div class="tabview-card">
      <TabView>
        <TabPanel :header="$t('progress_note')" class="soap-tab">
          <div class="small-patient-form soap-form">

            <div class="soap-item soap-item-s">
              <h5>Symptom</h5>
              <div class="formgroup grid-col-1">
                <div class="form-item">
                  <MultiSelect v-model="progress.symptoms" :options="symptoms" :filter="false" optionValue="id" optionLabel="title" display="chip"/>
                </div>
              </div>

              <div  v-if="other_symptoms" class="formgroup grid-col-1">
                <div class="form-item">
                  <label>{{$t('other_symptom')}}</label>
                  <div><InputText v-model="progress.symptoms_note" type="text" :placeholder="$t('other_symptoms')" /></div>
                </div>
              </div>

              <div v-if="progress.deleted_symptoms">
                <small><strong>Deleted symptoms:</strong> {{progress.deleted_symptoms}}</small>
              </div>
            </div>

            <div class="soap-item soap-item-o">
              <h5>Vital Signs</h5>
              <Spo2Read :data="spo2data" title="" @change="spo2change" :placeholder="progress.spo2_image_url" />
              <div class="formgroup grid-col-3">
                <div class="form-item">
                  <label>{{$t('temperature')}}</label>
                  <div>
                    <InputNumber v-model="progress.temp" mode="decimal" :min="0" :max="100" :minFractionDigits="2"  />
                  </div>
                  <small>&deg;C</small>
                </div>
                <div class="form-item">
                  <label>{{$t('rr')}}</label>
                  <div>
                    <InputNumber v-model="progress.rr" mode="decimal" :min="0" :max="1000"  />
                  </div>
                </div>
                <div class="form-item">
                  <label>BP</label>
                  <div>
                    <InputNumber v-model="progress.sbp" mode="decimal" :min="0" :max="1000" placeholder="SBP" />
                  </div>
                  <small>/</small>
                  <div>
                    <InputNumber v-model="progress.dbp" mode="decimal" :min="0" :max="1000" placeholder="DBP" />
                  </div>
                </div>
              </div>
            </div>

            <div class="soap-item soap-item-a">
              <h5>Assessment (Auto)</h5>
              {{progress.assessment?progress.assessment:'-'}}
            </div>

            <div class="soap-item soap-item-p">
              <h5>Plan</h5>
              <div class="small-patient-form-doctor" v-if="user.role==='doctor' || user.role==='nurse'">
                <Textarea v-model="progress.doctor_note" :autoResize="true" rows="2" style="width:100%" />
                <div v-if="user.role==='nurse'">
                  <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">รคส</span>
                      <Dropdown v-model="progress.doctor_id" :options="doctors" optionLabel="name" optionValue="id" placeholder="Please Select" style="width:100%" />
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="progress.doctor_note"><strong>{{$t('doctor_note')}}</strong><br/>{{progress.doctor_note}}</div>
              </div>
            </div>
            <br/>
            <div v-if="user.role==='staff' || user.role==='admin'">
              <h5>{{$t('note')}}</h5>
              <Textarea v-model="progress.staff_note" :autoResize="true" rows="2" style="width:100%" />
              <div v-if="progress.patient_note"><strong>{{$t('patient_note')}}</strong><br/>{{progress.patient_note}}</div>
            </div>
            <div v-else>
              <div v-if="progress.staff_note"><strong>{{$t('staff_note')}}</strong><br/>{{progress.staff_note}}</div>
              <div v-if="progress.patient_note"><strong>{{$t('patient_note')}}</strong><br/>{{progress.patient_note}}</div>
            </div>
          </div>
        </TabPanel>
        <TabPanel :header="$t('spo2_test')">
          <Spo2Read :data="spo2data_after" :title="$t('spo2_after_exercise')" @change="spo2change_after" :placeholder="progress.spo2_after_image_url" />
        </TabPanel>
      </TabView>
      <div class="dialog-form-buttons">
        <span class="p-button	p-button-text" @click="$emit('hide')">{{$t('cancel')}}</span>&nbsp;&nbsp;

        <template  v-if="progressnoteid===null || progress.status==='draft'">
          <Button type="button" :label="$t('save_draft')" class="p-button-secondary p-button-outlined" icon="pi pi-save" iconPos="right" @click="save('draft')"/>
        </template>
        &nbsp;
        <template  v-if="user.role==='doctor' || user.role==='nurse'">
          <Button type="button" :label="$t('save_and_sign')"  icon="pi pi-pencil" iconPos="right" @click="save('confirmed')"/>
        </template>
        <template v-else>
          <Button type="button" :label="$t('save_note')"  icon="pi pi-check" iconPos="right" @click="save('saved')"/>
        </template>
      </div>
    </div>
  </div>
  <div class="page-loading" v-else>
    <ProgressSpinner />
  </div>
  
</template>

<script>
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ProgressSpinner from 'primevue/progressspinner';
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';


import Spo2Read from '@/components/Spo2Read';
import SmallPatientCard from '@/components/SmallPatientCard';
import patient from '@/models/patient';
import { ProgressService } from '@/services/progress';
import { PatientService } from '@/services/patient';

export default {
  components: {
    Spo2Read,Button,SmallPatientCard,TabView,TabPanel,ProgressSpinner,Textarea,MultiSelect,InputNumber,InputText,Dropdown
  },
  data() {
    return {
      result: {},
      isReady: false,
      patient: patient,
      symptoms: [],
      doctors: [],
      patient_id: null,
      progress_id: null,
      progress: {},
      user: {}
    }
  },
  props: ['patientid','progressnoteid'],
  emits: ['change','hide'],
  mounted() {
    ProgressService.patient(this.patientid)
      .then((res)=>{
        this.result = res
      },(res)=>{
        this.errors = res
        this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
        this.$emit('hide')
      })
    ProgressService.get_form(this.patientid,this.progressnoteid)
      .then((res)=>{
        if(this.progressnoteid && res.progress_note) {
          this.mapPatient(res.progress_note)
        }
        this.symptoms = res.symptoms.choices
        this.user = res.user
        this.doctors = res.doctors
        this.isReady = true
      },(res)=>{
        this.errors = res
      })
  },
  methods: {
    mapPatient(progress_note) {
      let symptoms = []
      this.progress = progress_note
      if(progress_note.symptoms){
        progress_note.symptoms.map((item)=>{
          if(item.has_text==1)this.progress.symptoms_note = item.value
          symptoms.push(item.choice_id)
        })
      }
      this.progress.symptoms = symptoms
    },
    spo2change(data) {
      if(data.image_id)this.progress.spo2_image_id = data.image_id
      else this.progress.spo2_image_id = null
      this.progress.spo2 = data.spo2
      this.progress.pulse = data.pulse
    },
    spo2change_after(data) {
      if(data.image_id)this.progress.spo2_after_image_id = data.image_id
      else this.progress.spo2_after_image_id = null
      this.progress.spo2_after = data.spo2
      this.progress.pulse_after = data.pulse
    },
    save(status) {
      this.$store.dispatch('app/showLoading')
      this.progress.status = status
      ProgressService.save_progress(this.patientid,this.progress,this.progressnoteid)
        .then((res)=>{
          if(res.success) {
            if(res.messages && Array.isArray(res.messages) && res.messages.length>0) {
              this.$toast.add({severity:'success', summary: 'Save Successfully', detail:res.messages[0], life: 3000});
            }
            this.$emit('change')

            this.$store.dispatch('app/hideLoading')
          } else {
            this.$toast.add({severity:'error', summary: 'An Error Occurred', detail:'Unknown Error', life: 3000});
          }
        },(res)=>{
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    },
    changeTriage(color) {
      this.loading = true
      PatientService.change_triage(this.patientid,color)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: 'Change Triage Successfully', detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
        },(res)=>{
          this.loading = false
          this.errors = res
        })
    },
    orderItemClass(item) {
      if(item.status==='waiting') {
        return 'clock';
      } else if(item.status==='received') {
        return 'check';
      } else {
        return 'times';
      } 
    }
  },
  computed: {
    other_symptoms() {
      let result = false
      if(this.progress.symptoms) {
        this.progress.symptoms.map((item)=>{
          this.symptoms.map((sym)=>{
            if(sym.id === item && sym.has_text) {
              result = true
            }
          })
        })
      }
      return result
    },
    spo2data() {
      if(this.progress) {
        //console.log(this.progress)
        return { spo2: this.progress.spo2, pulse: this.progress.pulse }
      } else {
        return null
      }
    },
    spo2data_after() {
      if(this.progress) {
        return { spo2: this.progress.spo2_after, pulse: this.progress.pulse_after }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.soap-form {
  // background: red;
  .soap-item {
    padding: 10px 0;
    position: relative;
    border-bottom:1px solid var(--surface-c);
    h5 {
      padding-left: 30px !important;
    }
  }
  .soap-item:before {
    display: block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height:25px;
    border-radius: 25px;
    font-weight: bold;
    background: var(--primary-color);
    color: var(--primary-color-text);
    position: absolute;
    left: 0;
    top: 12px;
  }
  
  .soap-item-s:before{
    content: "S";
  }
  .soap-item-o:before{
    content: "O";
  }
  .soap-item-a:before{
    content: "A";
  }
  .soap-item-p:before{
    content: "P";
  }
}
</style>