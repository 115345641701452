{
  "hn": "H.N.",
  "gender": "Gender",
  "age": "Age",
  "admitted": "Admitted",
  "ward": "Ward",
  "bed": "Bed",
  "spo2": "SpO2",
  "bp": "BP",
  "pulse": "Pulse",
  "clear_image": "Clear Image",
  "take_photo": "Take Photo",
  "drag_and_drop": "Drag and Drop",
  "or_click_to_choose_file": "or Click to Choose File",
  "open_camera": "Open Camera",
  "green": "Green",
  "yellow": "Yellow",
  "red": "Red",
  "register": "Register",
  "dashboard": "Dashboard",
  "record": "Record",
  "medicine": "Medicine",
  "new_patient": "New Patient",
  "total_patient": "Total Patient",
  "daily_admission": "Daily Admission",
  "daily_discharge": "Daily Discharge",
  "daily_transfer": "Daily Transfer",
  "transfer_pending": "Transfer Pending",
  "name": "Name",
  "note": "Note",
  "go_back": "Go Back",
  "records": "Records",
  "date": "Date",
  "time": "Time",
  "symptoms": "Symptoms",
  "chart": "Chart",
  "oxsat": "OxSat",
  "upload_image_to_auto_read": "Upload Image to Auto Read",
  "temperature": "Temperature",
  "rr": "RR",
  "blood_pressure": "Blood Pressure",
  "sbp": "SBP",
  "dbp": "DBP",
  "symptom": "Symptom",
  "cancel": "Cancel",
  "save": "Save",
  "register_patient": "Register Patient",
  "initial": "Initial",
  "firstname": "Firstname",
  "lastname": "Lastname",
  "dob": "Date of Birth",
  "please_select_ward": "Please Select Ward",
  "covid_result": "COVID Result",
  "covid_test_type": "Test Type",
  "ud": "UD",
  "cxr": "CXR",
  "add_patient": "Add Patient",
  "save_successfully": "Save Successfully",
  "line_passcode": "Line Passcode",
  "back_to_dashboard": "Back to Dashboard",
  "male": "Male",
  "female": "Female",
  "unknown": "Unknown",
  "positive": "Positive",
  "hello": "hello",
  "progress_note": "Progress note",
  "image": "Image",
  "triage": "Triage",
  "temp": "Temp",
  "date_time": "Date/Time",
  "spo2_test": "SpO2 Test",
  "spo2_before_exercise": "SpO2 Before Exercise",
  "spo2_after_exercise": "SpO2 After Exercise",
  "save_draft": "Save Draft",
  "save_note": "Save Note",
  "confirm_change_triage": "confirm change triage",
  "notify_doctor": "notify doctor",
  "notify_doctor_reason": "notify doctor reason",
  "send_notification_to_doctors": "send notification to doctors",
  "discharge": "discharge",
  "discharge_reason": "discharge reason",
  "discharge_date": "discharge date",
  "discharge_patient_note": "discharge patient note",
  "do_discharge": "do discharge",
  "dead": "dead",
  "move_to_ci": "move to ci",
  "move_status": "move status",
  "move_date": "move date",
  "transfer_to_hospital": "transfer to hospital",
  "transfer_status": "transfer status",
  "transfer_date": "transfer date",
  "edit_patient": "edit patient",
  "move_patient": "move patient",
  "request_move_to_ci": "request move to ci",
  "request_transfer_to_hospital": "request transfer to hospital",
  "citizen_id": "citizen id",
  "phone_number": "phone number",
  "address": "address",
  "family_information": "family information",
  "family_number": "family number",
  "covid_positive": "covid positive",
  "health_coverage_information": "health coverage information",
  "health_coverage": "health coverage",
  "insurance_paper": "insurance paper",
  "upload_profile_image": "upload profile image",
  "drag_or_click_to_upload_profile_picture": "drag or click to upload profile picture",
  "upload_id_card": "upload id card",
  "ci_information": "ci information",
  "covid_test": "covid test",
  "covid_test_date": "covid test date",
  "covid_test_image": "covid test image",
  "test_date": "test date",
  "health_information": "health information",
  "hx_allergy": "hx allergy",
  "hx_medication": "hx medication",
  "universal_coverage": "universal coverage",
  "social_security": "social security",
  "civil_servant": "civil servant",
  "private": "private",
  "add_tag": "add tag",
  "edit_tags": "edit tags",
  "type_to_add_new_tag": "type to add new tag",
  "save_tags": "save tags",
  "doctor_note": "doctor note",
  "upload_oxsat_image_to_auto_read": "upload oxsat image to auto read",
  "waiting_list": "waiting list",
  "patient_dashboard": "patient dashboard",
  "fulfillments": "fulfillments",
  "picture": "picture",
  "color": "color",
  "round": "round",
  "all": "all",
  "id": "id",
  "item": "item",
  "add_progress_note": "add progress note",
  "click_to_round": "click to round",
  "approve_patient": "approve patient",
  "patient_register": "patient register",
  "total_waiting": "total waiting",
  "daily_register": "daily register",
  "status": "status",
  "symptom_start_date": "symptom start date",
  "received_at": "received",
  "ordered_at": "ordered",
  "no_order": "no order",
  "edit_order": "edit order",
  "doctor_order": "doctor order",
  "save_order": "save order",
  "contacted_orgs": "contacted orgs",
  "id_card_image": "id card image",
  "approve": "approve",
  "save_and_sign": "save and sign",
  "last_round": "last round",
  "last_progress_note": "last progress note",
  "admit": "admit",
  "edit": "edit",
  "draft": "draft",
  "saved": "saved",
  "confirmed": "confirmed",
  "cancelled": "cancelled",
  "discharge_date_time": "discharge date time",
  "transfer_patient": "transfer patient",
  "move_to": "move to",
  "refer_to_hispital": "refer to hispital",
  "ward_bed": "ward/bed",
  "transfer_hospital": "transfer hospital",
  "choose_or_type_tags": "choose or type tags",
  "ordered": "ordered",
  "sent": "sent",
  "item_sent": "item sent",
  "transferred": "transferred",
  "request_for_transfer": "request for transfer",
  "transfer_request": "transfer request",
  "phone": "phone",
  "process": "process",
  "transfer": "transfer",
  "view_detail": "view detail",
  "patient_action": "patient action",
  "view_patient": "view patient",
  "close": "close",
  "transfer_or_move": "transfer or move",
  "unit_information": "unit information",
  "type": "type",
  "ward_zone": "Ward/Zone",
  "community_isolation": "Community Isolation",
  "home_isolation": "Home Isolation",
  "staff_note": "staff note",
  "patient_note": "patient note",
  "day": "day",
  "view": "view",
  "covid_day": "Covid Day",
  "unit": "Unit",
  "last_progress": "Last Progress",
  "print": "Print",
  "print_medical_file": "Print Medical File",
  "creating_pdf_file": "Creating PDF File...",
  "progress": "Progress",
  "other_symptom": "Other Symptom",
  "other_symptoms": "Other Symptoms",
  "other_uds": "Other UD",
  "please_check_or_click_to_change_triage": "Please check triage, or click to change value",
  "triage_change_detected_to": "Recommend change triage to ",
  "do_you_want_to_change_triage": "Do you want to change triage?",
  "preparing": "Preparing",
  "consent_form": "Consent Form",
  "order_medicine_and_equiptment": "Order Medicine & Equiptments",
  "new_waiting_patient": "Add New Waiting Patient",
  "new_patient_added": "New Patient Added",
  "weight": "Weight",
  "height": "Height",
  "patient_list": "Patient List",
  "no_record_found": "No Record Found",
  "transfer_requests": "Transfer Requests",
  "no_ward": "No Ward",
  "discharge_list": "Discharge List",
  "admission_count": "Admission Count",
  "today": "Today",
  "patient_triage_count": "Patient Triage Count",
  "progress_note_count": "Progress Note Count",
  "patient_ward_count": "Patient by Ward",
  "total_cases": "Total Cases",
  "cumulative_case_count": "Cumulative Cases Count",
  "patient_age_range": "Patient by Age",
  "patient_gender_count": "Patient by Gender",
  "discharge_statistic": "Discharge Statistic",
  "discharge_disposition": "Discharge Disposition",
  "discharge_count": "Discharge Count",
  "patient_count": "Patient Count",
  "current_admit": "Current Patient",
  "waiting_patient": "Waiting Patient",
  "sign_medical_certificate": "Sign Medical Certificate",
  "confirm_sign_medical_certificate": "Confirm Sign Medical Certificate",
  "sign_medical_Certification": "Sign Medical Certification",
  "print_medical_Certification": "Print Medical Certificate",
  "signing_medical_certification": "Signing Medical Certificate",
  "sign_successfully": "Sign Successfully",
  "print_admission_note": "Print Admission Note",
  "creating_admission_note": "Creating Admission Note",
  "hospital_ward": "Hospital Ward",
  "discharged": "Discharged"
}