<template>
  <div>
    <div class="display-mode-toggle" @click="toggleTheme">
      <i class="pi pi-moon" v-if="$store.state.app.theme==='light'"></i>
      <i class="pi pi-sun" v-if="$store.state.app.theme==='dark'"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      theme: null
    }
  },
  mounted(){
    this.setDisplayTheme()
  },
  methods: {
    toggleTheme(){
      this.$store.dispatch('app/toggleTheme')
      this.setDisplayTheme()
    },
    setDisplayTheme() {
      document.documentElement.setAttribute('data-theme', this.$store.state.app.theme)
    }
  }
}
</script>

<style lang="scss">
.display-mode-toggle {
  display: block;
  padding: 0 10px;
  text-align: center;
}
</style>