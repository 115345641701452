<template>
  <app-header :nav="false" />
  <div class="home">
    <div class="home-nav">
      <router-link to="/main/dashboard"><i class="pi pi-chart-bar"></i> {{$t('dashboard')}}</router-link>
      <router-link to="/main/patients"><i class="pi pi-users"></i> {{$t('patient_list')}}</router-link>
      <router-link to="/main/progress"><i class="pi pi-pencil"></i> {{$t('progress_note')}}</router-link>
      <router-link to="/main/fulfillments"><i class="pi pi-briefcase"></i> {{$t('fulfillments')}}</router-link>
      <router-link to="/main/request_transfer"><i class="pi pi-reply"></i>  {{$t('transfer_request')}}</router-link>
      <router-link to="/main/waiting_list"><i class="pi pi-user-plus"></i> {{$t('waiting_list')}}</router-link>
      <router-link to="/main/discharge_list"><i class="pi pi-sign-out"></i> {{$t('discharge_list')}}</router-link>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/Header.vue';

export default {
  components: {
    AppHeader
  },
  mounted() {
    
  }
}
</script>

<style scoped lang="scss">
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 40px );
}
.home-nav {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 15px;


  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(1,1fr);
  }

  i {
    font-size: 28px;
    line-height: 2.6em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 10px;
    background: rgba(0,0,0,0.16);
    border-radius: 10px 0 0 10px;
  }

  &>a {
    position: relative;
    padding: 30px 30px 30px 70px;
    background: var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color-text);
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: left;
    transition: background-color 0.4s;
    &:hover {
      background: var(--secondary-color);
    }
  }
}
</style>