<template>
  <span v-if="interpretation" :class="'interpretation-text '+classname">{{interpretation.value}}</span>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  props: ['interpretation'],
  mounted() {
  },
  methods: {
  },
  computed: {
    classname() {
      if(this.interpretation && this.interpretation.interpretation === 'N') {
        return 'interpretation-green'
      } else {
        return 'interpretation-red'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.interpretation-text {
  &.interpretation-green {
    color: #08A84E;
  }
  &.interpretation-red {
    font-weight: bold;
    color: #D41A0F;
  }
}
</style>