<template>
  <div class="spo2-read-wrapper">
    <div class="spo2-form">
      <h5 v-if="title">{{title}}</h5>
      <p v-if="description">{{description}}</p>
      <div class="formgroup grid-col-2">
        <div class="form-item">
          <label>{{$t('spo2')}}</label>
          <div>
            <InputNumber v-model="current_spo2" mode="decimal" :min="0" :max="100" />
          </div>
          <small>%</small>
        </div>
        <div class="form-item">
          <label>{{$t('pulse')}}</label>
          <div>
            <InputNumber v-model="current_pulse" mode="decimal" :min="0" :max="1000" />
          </div>
        </div>
      </div>
    </div>
    <div class="spo2-image">
      <take-photo size="small" :camera="false" @change="imageChanged" :title="$t('upload_oxsat_image_to_auto_read')" :placeholder="placeholder"/>
      <div v-if="reading_image" class="reading_image"><ProgressSpinner /><small>Reading Image...</small></div>
    </div>
  </div>
</template>

<script>
import { PatientService } from '@/services/patient';
import TakePhoto from '@/components/TakePhoto';
import { EtcService } from '@/services/etc';
import ProgressSpinner from 'primevue/progressspinner';

import InputNumber from 'primevue/inputnumber';
export default {
  components: {
    TakePhoto,InputNumber,ProgressSpinner
  },
  data () {
    return {
      loading: false,
      reading_image: false,
      current_id: null,
      current_spo2: null,
      current_pulse: null
    }
  },
  props: ['data','title','description','placeholder'],
  emits: ['change'],
  mounted() {
    this.current_id = this.data.id
    this.current_spo2 = this.data.spo2
    this.current_pulse = this.data.pulse
  },
  watch: {
    current_pulse() {
      this.changed()
    },
    current_spo2() {
      this.changed()
    }
  },
  methods: {
    inputChanged: function (e) {
      this.changed()
      return true
    },
    imageChanged(data){
      this.image = data
      let formdata = new FormData()
      if(this.image) {
        this.reading_image = true
        formdata.append('image', this.image);
        EtcService.pacman_spo2(formdata)
          .then((res)=>{
            this.current_id = res.image_id
            if(res.success === 1){
              this.current_spo2 = res.spo2
              this.current_pulse = res.pulse
            }
            this.changed()
            this.reading_image = false
          },(res)=>{
            this.errors = res
          })
      }
    },
    changed() {
      let data = {"spo2":this.current_spo2,"pulse":this.current_pulse,"image_id":this.current_id}
      
      this.$emit('change', data)
    }
  }
}
</script>

<style lang="scss">
.spo2-read-wrapper {
  display: grid;
  grid-template-columns: auto 200px;
  gap: 10px;

  @media screen and (max-width: 576px) {
    grid-template-columns: auto 150px;
  }
  .spo2-form {
    margin-right: 10px;
  }
  
  .spo2-image {
    position: relative;
    .take-photo-wrapper {
      max-width:300px;
      margin: 0 0 0 auto;
    }
    .reading_image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255,0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>