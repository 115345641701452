<template>
  <Sidebar v-model:visible="$store.state.app.sidebarActive" :dismissable="true" position="left">
    <Menu :model="items" class="sidebar-menu" />
  </Sidebar>
</template>

<script>
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
export default {
  components: {
    Sidebar,Menu
  },
  props: {
  },
  data() {
    return {
      items: [
        { "label": this.$t("dashboard"), "icon": 'pi pi-chart-bar', "to": "/main/dashboard", "command": this.onItemSelect },
        { separator: true },
        { "label": this.$t("patient_list"), "icon": 'pi pi-users', "to": "/main/patients", "command": this.onItemSelect },
        { "label": this.$t("progress_note"), "icon": 'pi pi-pencil', "to": "/main/progress","command": this.onItemSelect },
        { separator: true },
        { "label": this.$t("fulfillments"), "icon": 'pi pi-briefcase', "to": "/main/fulfillments","command": this.onItemSelect },
        { "label": this.$t("transfer_requests"), "icon": 'pi pi-reply', "to": "/main/request_transfer","command": this.onItemSelect },
        { separator: true },
        { "label": this.$t("waiting_list"), "icon": 'pi pi-user-plus', "to": "/main/waiting_list", "command": this.onItemSelect },
        { separator: true },
        { "label": this.$t("discharge_list"), "icon": 'pi pi-sign-out', "to": "/main/discharge_list", "command": this.onItemSelect },
        { separator: true }
			],
      is_active: false
    }
  },
  mounted() {
  },
  methods: {
    onItemSelect(event) {
      this.$store.dispatch('app/toggleSidebarActive')
    }
  },
  computed: {
  }
}
</script>

<style>
.layout-sidebar {
  z-index: 999;
}
</style>