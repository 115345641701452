import store from '@/store'

export default function authHeader() {
  //console.log(store.getters['user/token'])
  if (store.getters['user/loggedIn'] && store.getters['user/token']) {
    return {
      'Authorization': 'Bearer ' + store.getters['user/token'],
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  } else {
    return {};
  }
}