<template>
  <div class="text-button">
    <i :class="icon"></i>
  </div>
</template>

<script>
export default {
  props: {
    icon: String
  }
}
</script>

<style scoped lang="scss">
.text-button {
  display:inline-block;
  cursor: pointer;
  padding: 0 1em;
}
</style>
