<template>
  <div class="patient-tags">
    <div class="patient-tag" v-for="(item,index) in new_tags" :key="'tag-'+index">
      {{item}}
    </div>
    <div class="patient-tag-add" v-if="editable" @click="showTagDialog">
      &plus; {{$t('edit_tags')}}
    </div>
  </div>

  <Dialog :header="$t('edit_tags')" v-model:visible="adding" class="medium-dialog">
    <h5>{{$t('choose_or_type_tags')}}</h5>

    <div class="tags-box">
      <Chips v-model="new_tags" :placeholder="$t('type_to_add_new_tag')" />
    </div>

    <div class="suggest-tags">
      <Chip v-for="(text,index) in all_tags" :label="text" :key="'tag-'+index" @click="addSuggestTag(text)" />
    </div>

    <div class="text-right">
      <Button type="button" icon="pi pi-tag" :label="$t('save_tags')" iconPos="left" @click="doAddTag" />
    </div>
  </Dialog>
</template>

<script>
import { PatientService } from '@/services/patient';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Chips from 'primevue/chips';
import Chip from 'primevue/chip';

export default {
  components: {
    Dialog,Button,Chips,Chip
  },
  data () {
    return {
      adding: false,
      new_tags: [],
      filtered_tags: [],
      all_tags: []
    }
  },
  props: ['editable','tags','id'],
  emits: ['change'],
  mounted() {
    if(this.tags)this.new_tags = this.tags
  },
  methods: {
    showTagDialog() {
      this.adding = true
      PatientService.get_tags()
        .then((res)=>{
          this.loading = false
          this.all_tags = res
        },(res)=>{
          this.loading = false
          this.errors = res
        })
    },
    doAddTag() {
      let data = {
        tag_name: this.new_tags
      }
      PatientService.add_tag(this.id,data)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: this.$t('save_successfully'), detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
        },(res)=>{
          this.loading = false
          this.errors = res
        })
      this.adding = false
    },
    addSuggestTag(tag) {
      this.new_tags.push(tag)
      this.removeDuplicates()
    },
    removeDuplicates () {
      this.new_tags = [ ...new Set(this.new_tags) ]
    }
  }
}
</script>

<style lang="scss">
.tags-box {
  margin: 0 0 10px;
  .p-chips, .p-inputtext {
    width: 100%;
  }
}
.suggest-tags {

  .p-chip {
    margin: 2px;
    cursor:pointer;
  }
}
.patient-tags {
  font-size: 0.9em;
  line-height: 1.4em;
  font-weight: bold;
  .patient-tag {
    display: inline-block;
    padding: 0 5px;
    border-radius: 3px;
    margin: 1px 3px 1px 0;
    background: var(--primary-color);
    color: var(--primary-color-text);
  }
  .patient-tag-add {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
  }
}
</style>