<template>
  <div>
    <div v-if="triages">
      <h5>{{$t('note')}}</h5>
      <Textarea v-model="note" :autoResize="true" rows="2" style="width:100%" />

      <h5>{{$t('triage')}}</h5>
      <Dropdown style="width:100%" v-model="selected_triage" :options="triages" optionValue="code" optionLabel="text" placeholder="Triages">
        <template #option="slotProps">
          <i class="triage-dot" :style="'background:'+slotProps.option.color"></i>&nbsp;&nbsp;<span>{{slotProps.option.text}}</span>
        </template>
      </Dropdown>
      <p class="text-right">
      <br/>
        <Button type="button" :label="$t('save_note')" class="p-btn p-btn-primary"  icon="pi pi-check" iconPos="right" @click="submitChange"/>
      </p>
    </div>
  </div>
</template>

<script>
import { PatientService } from '@/services/patient';
import { EtcService } from '@/services/etc';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';


export default {
  components: {
    Dropdown,Textarea,Button
  },
  data () {
    return {
      loading: false,
      selected_triage: null,
      triages: [],
      note: null
    }
  },
  emits: ['change'],
  props: ['progressnoteid','patientid','triage'],
  mounted() {
    this.selected_triage = this.triage.code
    EtcService.triages()
        .then((res)=>{
          this.triages = res.triage
        },(res)=>{
          this.loading = false
          this.errors = res
        })
  },
  methods: {
    toggleMenu() {
      this.$refs.actionmenu.toggle(event);
    },
    submitChange() {
      this.$store.dispatch('app/showLoading')
      this.loading = true
      PatientService.change_triage(this.progressnoteid,this.selected_triage)
        .then((res)=>{
          this.loading = false
          if(res.success && res.messages) {
            this.$toast.add({severity:'success', summary: 'Save Successfully', detail:res.messages[0], life: 3000});
          }
          this.$emit('change')
          this.$store.dispatch('app/hideLoading')
        },(res)=>{
          this.loading = false
          this.errors = res
          this.$store.dispatch('app/hideLoading')
        })
    }
  }
}
</script>