import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const PatientService = {
  patients,
  patient,
  save_patient,
  get_form,
  change_triage,
  discharge,
  order,
  notify_doctor,
  transfer,
  move,
  add_tag,
  get_tags,
  passcode,
  print,
  doctor_sign,
  print_cert,
  print_admission,
  telemed
};

function patients (ward) {
  const params = new URLSearchParams();
  params.append('param1', 'value1')
  params.append('param2', 'value2')
  if( ward ) {
    params.append('ward_id', ward)
  } else {
    params.append('ward_id', 'none')
  }
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/list`,
      method: 'GET',
      params: params,
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}


function get_form (id) {
  return new Promise((resolve, reject) => {
    let params = ''
    if(id)params = '/'+id
    axios({
      url: `${REST_ENDPOINT}patient/form${params}`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown Error'])
        }
      }
    })
  })
}

function patient (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/${id}`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown Error'])
        }
      }
    })
  })
}

function passcode (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/${id}/new-passcode`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown Error'])
        }
      }
    })
  })
}

function save_patient (data,id) {
  let params = ''
  if(id)params = '/'+id
  let header = {...{"Content-Type": "multipart/form-data"},...authHeader()}
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient'+params,
      method: 'POST',
      headers: header,
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function change_triage (id,value) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient/triage-status/'+id,
      method: 'POST',
      headers: authHeader(),
      data: {triage_status:value}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}


function discharge (id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient/'+id+'/discharge',
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function order (id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient/'+id+'/order',
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function notify_doctor (id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient/'+id+'/notify',
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function doctor_sign (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'patient/'+id+'/med-cert/sign',
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}



function transfer (pid,data,id) {
  let params = ''
  if(id)params = '/'+id
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+`patient/${pid}/transfer-request${params}`,
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function move (pid,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+`patient/${pid}/move`,
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function add_tag (pid,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+`patient/${pid}/tag/add`,
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown Error'])
      }
    })
  })
}

function get_tags () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}tag`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function print (pid) {
  return new Promise((resolve, reject) => {
    let headers = authHeader()
    headers.accept = 'application/pdf'
    axios({
      url: `${REST_ENDPOINT}patient/${pid}/report`,
      method: 'GET',
      headers: headers,
      responseType: 'blob'
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function print_admission (pid) {
  return new Promise((resolve, reject) => {
    let headers = authHeader()
    headers.accept = 'application/pdf'
    axios({
      url: `${REST_ENDPOINT}patient/${pid}/admission-note`,
      method: 'GET',
      headers: headers,
      responseType: 'blob'
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function print_cert (pid) {
  return new Promise((resolve, reject) => {
    let headers = authHeader()
    headers.accept = 'application/pdf'
    axios({
      url: `${REST_ENDPOINT}patient/${pid}/med-cert/print`,
      method: 'GET',
      headers: headers,
      responseType: 'blob'
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function telemed (pid) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}patient/${pid}/video/open`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(!error || !error.response) { reject(['Unknown']) }
      else if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}