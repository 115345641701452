<template>
  <div class="content-container" v-if="isReady">
    <div class="grid">
      <div class="col">
        <h1>{{$t('progress_note')}}</h1>
      </div>
    </div>
    <div class="grid">
      <div class="col-7">
        <Dropdown v-model="filter_ward" :options="wards" optionValue="id" optionLabel="name" placeholder="Wards" style="min-width:150px">
          <template #option="slotProps">
            <span>{{slotProps.option.name}}</span>
          </template>
        </Dropdown>
      </div>
      <div class="col-5">
        <div class="p-inputgroup">
            <InputText v-model="filter_keyword" placeholder="Keyword"/>
            <Button icon="pi pi-search" />
        </div>
      </div>
    </div>

    <DataTable :value="patients" responsiveLayout="scroll" showGridlines stripedRows class="p-datatable-sm" 
      :paginator="true" :rows="20" selectionMode="single" dataKey="hn" @rowSelect="onRowSelect">
      <Column headerStyle="width: 3em;" bodyStyle="padding:0;line-height:0;" field="profile_pic" header="" >
        <template #body="slotProps">
          <img :src="slotProps.data.image" class="product-image" width="50" />
        </template>
      </Column>
      <Column field="triage" :header="$t('triage')" class="text-center">
        <template #body="slotProps">
          <TriageBadgeStatus :triage="slotProps.data.triage" size="mini" />
        </template>
      </Column>
      <Column field="ward" :header="$t('unit')" :sortable="true" class="text-center">
        <template #body="slotProps">
          <strong>{{slotProps.data.ward}}</strong>
          <span>{{slotProps.data.bed}}</span>
        </template>
      </Column>
      <Column field="hn" :header="$t('hn')" :sortable="true" class="text-center"></Column>
      <Column field="fullname" :header="$t('name')" :sortable="true"></Column>
      <Column field="phone" :header="$t('phone')" :sortable="true" class="text-center"></Column>
      <Column field="status" headerStyle="width: 2em;" header="" :sortable="true" class="text-center">
        <template #header>
          <i class="pi pi-check"></i>
        </template>
        <template #body="slotProps">
          <span  v-if="slotProps.data.status==='draft'"><i class="pi pi-pencil"></i> <small>{{$t('draft')}}</small></span>
          <span  v-else-if="slotProps.data.status==='saved'"><i class="pi pi-check"></i></span>
          <span  v-else-if="slotProps.data.status==='finished'"><i class="pi pi-check"></i></span>
          <span  v-else-if="slotProps.data.status==='confirmed'"><i class="pi pi-check"></i></span>
        </template>
      </Column>
      <Column field="progress_note_round" :header="$t('round')" :sortable="true" class="text-center">
      </Column>
      <template #empty>
        <div class="text-center">{{$t('no_record_found')}}</div>
      </template>
    </DataTable>
  </div>
  <div class="page-loading" v-else>
    <ProgressSpinner />
  </div>
  <Dialog :modal="true" header="Progress Note" v-model:visible="patient_display" class="large-dialog" @hide="progressNoteClose">
    <ProgressNote :patientid="patient_display_id" :progressnoteid="patient_display_progress_note_id" @change="progressNoteChanged" @hide="progressNoteClose" role="staff"/>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

import TriageBadgeStatus from '@/components/TriageBadgeStatus';
import ProgressSpinner from 'primevue/progressspinner';
import patient from '@/models/patient';
import { ProgressService } from '@/services/progress';

import ProgressNote from '@/components/ProgressNote';

export default {
  components: {
    Button,InputText,DataTable,Column,TriageBadgeStatus,ProgressSpinner,Dialog,ProgressNote,Dropdown
  },
  data() {
    return {
      isReady: false,
      filter_ward: null,
			wards: [],
      response: [],
      patient_display_progress_note_id: null,
      patient_display_id: null,
      patient_display: false,
      filter_keyword: null
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    loadList() {
      ProgressService.list()
        .then((res)=>{
          if(res) {
            this.response = res
            this.wards = [{id:null,name:this.$t('all')}].concat(res.wards)
            this.isReady = true
          } else {
            this.errors = "Invalid Access Token"
          }
        },(res)=>{
          this.errors = res
        })
    },
    onRowSelect(event) {
      this.patient_display_id = event.data.id
      this.patient_display_progress_note_id = event.data.progress_note_id
      this.patient_display = true
      //this.$router.push('/main/progress/'+event.data.id)
    },
    progressNoteClose() {
      this.patient_display_id = null
      this.patient_display_progress_note_id = null
      this.patient_display = false
    },
    progressNoteChanged() {
      this.loadList()
      this.progressNoteClose()
    }
  },
  computed : {
    patients() {
      if(!this.response || !this.response.list)return;
      let patients = []
      this.response.list.map((item)=>{
        if(this.filter_ward && item.ward_id != this.filter_ward)return
        if(this.filter_keyword) {
          let searchvals = [item.hn,item.fullname]
          searchvals = searchvals.join()
          if (searchvals.toLowerCase().indexOf(this.filter_keyword) < 0) {
            return
          }
        }
        patients.push({
          "id": item.id,
          "image": item.profile_pic,
          "ward": item.ward,
          "bed": item.bed,
          "gender": item.gender,
          "hn": item.hn,
          "fullname": item.fullname,
          "age": item.age,
          "phone": item.mobile,
          "progress_note_id": item.shift_progress_note_id,
          "progress_note_round": item.shift_progress_note_round,
          "triage": item.triage,
          "status": item.progress_note_status
        });
      })
      return patients
    },
  }
}
</script>

<style lang="scss">
</style>